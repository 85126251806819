import { Profile } from "../../interfaces";

export const populatePersonalInfoInitialFormValues = (profile?: Profile) => {
  if (!profile) {
    return;
  }

  return {
    firstName: profile.name?.split(" ")?.[0],
    lastName: profile.name?.split(" ")?.[1],
    mailId: profile.email,
    phone: profile.phone,
    gender: profile.gender,
    address: profile.streetAddress,
    country: profile.country,
    state: profile.state,
    city: profile.city,
  }
};
