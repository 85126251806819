import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { ConfigProvider, App as AntApp } from "antd";
import APP_THEME_CUSTOMIZATION from "./shared/constants/app-theme-customization";
import { PersistGate } from "redux-persist/integration/react";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element.");

const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider theme={APP_THEME_CUSTOMIZATION}>
        <AntApp>
          <App />
        </AntApp>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);
