import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

import {
  onboardingSlice,
  authSlice,
  profilesSlice,
  dashboardSlice,
} from "./slices";
import { authApi, dashboardApi, onboardingApi, paymentApi, profilesApi } from "./apis";

export const createNoopStorage = () => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: unknown) {
    return Promise.resolve(value);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

export const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const authPersistConifg = {
  key: "auth",
  whitelist: [],
  storage,
  keyPrefix: "redux-",
};

export const onboardingPersistConifg = {
  key: "onboarding",
  blacklist: [],
  storage,
  keyPrefix: "redux-",
};

export const profilesPersistConifg = {
  key: "profiles",
  blacklist: [],
  storage,
  keyPrefix: "redux-",
};

export const dashboardPersistConfig = {
  key: "dashboard",
  blacklist: [],
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  /**
   * From apis
   */
  [authApi.reducerPath]: authApi.reducer,
  [onboardingApi.reducerPath]: onboardingApi.reducer,
  [profilesApi.reducerPath]: profilesApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,

  /**
   * From slices
   */
  [authSlice.name]: persistReducer(authPersistConifg, authSlice.reducer),
  [onboardingSlice.name]: persistReducer(
    onboardingPersistConifg,
    onboardingSlice.reducer
  ),
  [profilesSlice.name]: persistReducer(
    profilesPersistConifg,
    profilesSlice.reducer
  ),
  [dashboardSlice.name]: persistReducer(
    dashboardPersistConfig,
    dashboardSlice.reducer
  ),
});

export default rootReducer;
