import React from "react";
import { Flex, Spin } from "antd";

export const LoadingView = () => {
  return (
    <Flex className="justify-center items-center h-lvh">
      <Spin size="large" />
    </Flex>
  );
};
