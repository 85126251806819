import appLogo from "./../../assets/icons/app-logo.svg";
import { Flex, Image } from "antd";
import NotificationsPopover from "./notifications-popover";
import ProfileDropdown from "./profile-dropdown";
import { useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";

export default function NavBar() {
  const navigate = useNavigate();
  return (
    <Header className="bg-white flex flex-row items-center justify-between px-6">
      <Image
        width="150px"
        className="cursor-pointer"
        src={appLogo}
        alt="App logo"
        preview={false}
        onClick={() => {
          navigate(PATH_DEFINITIONS.dashboard);
        }}
      />

      <Flex className="gap-4" align="center">
        <NotificationsPopover />
        <ProfileDropdown />
      </Flex>
    </Header>
  );
}
