import { baseApi } from "./base.api";

export const paymentApi = baseApi("paymentApi").injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    cancelSubscription: build.mutation<CancelSubscriptionRes, void>({
      query: () => ({
        url: `/payment/cancel-subscription`,
        method: "DELETE"
      }),
    }),
    getSubscriptionDetail: build.query<GetSubscriptionDetailRes, void>({
      query: () => ({
        url: `/payment/get-subscription-detail`,
        method: "GET"
      })
    }),
    createCheckoutSession: build.query<CreateCheckoutSessionRes, void>({
      query: () => ({
        url: '/payment/create-checkout-session',
        method: 'POST'
      })
    })
  })
});

export const { useCancelSubscriptionMutation, useGetSubscriptionDetailQuery, useCreateCheckoutSessionQuery, useLazyCreateCheckoutSessionQuery } = paymentApi;

export type GetSubscriptionDetailRes = {
  cancel_at_period_end: number;
  current_period_end: number;
  start_date: number;
};
export type CancelSubscriptionRes = {
  message: string;
};

export type CreateCheckoutSessionRes = {
  redirectUrl: string;
}
