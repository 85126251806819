import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { User } from "../../interfaces";
import { authApi, onboardingApi } from "../apis";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";

export interface AuthState {
  isInitialized: boolean;
  initialPath: string;
  token?: string;
  user?: User;
}

const initialState: AuthState = {
  isInitialized: false,
  initialPath: PATH_DEFINITIONS.login,
  token: undefined,
  user: undefined,
};

/**
 * Slice
 */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuth: (state, action: PayloadAction<Partial<AuthState>>) => {
      return { ...state, ...action.payload };
    },
    resetAuth: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      onboardingApi.endpoints.onboarding.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      }
    );
    builder.addMatcher(
      authApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      }
    );
  },
});

/**
 * Actions
 */
export const { updateAuth, resetAuth } = authSlice.actions;

/**
 * Selectors
 */
export const selectAuthSlice = (state: AppState) => state.auth;
export const selectRolesSlice = (state: AppState) => state.auth.user?.roles;
