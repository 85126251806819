import { getMetadata, getFormattedNumber } from "react-phone-hooks";

export const formatPhoneNumber = (value: string) => {
  try {
    if (!value?.trim()) {
      return "";
    }
  
    return getFormattedNumber(value, getMetadata(value)?.[3]!);
  } catch (error) {
    return ""
  }
};
