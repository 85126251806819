import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./register-wizard-forms-customization.scss";
import { useCallback } from "react";
import ReviewCard from "../../review-card/review-card";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import { WorkExperienceFormData } from "../../../interfaces";
import { useDispatch, useSelector } from "../../../store";

export interface Props {
  onEdit?: (key: number) => void;
  onAdd?: () => void;
  onNext?: () => void;
}

export default function WorkExperienceReview({
  onAdd = () => { },
  onNext = () => { },
  onEdit = () => { },
}: Props) {
  const { workExperiences = [] } = useSelector(selectOnboardingSlice);
  const dispatch = useDispatch();

  const onDelete = useCallback((key: number) => {
    dispatch(updateOnboarding({ workExperiences: workExperiences?.filter((_, index) => index !== key) }))
  }, [workExperiences, dispatch]);

  function renderReviewCardByValues() {
    if (!workExperiences?.length) {
      return null;
    }

    const items = workExperiences.map(
      (value: WorkExperienceFormData, i: number) => ({
        key: i,
        title: value.jobTitle,
        subtitles: [
          value.companyName,
          `${value.startMonth}/${value.startYear} - ${value.currentlyWorking
            ? "Present"
            : `${value.endMonth || ""}${value.endMonth ? "/" : ""}` +
          `${value.endYear || ""}`
          }`,
        ],
      })
    );
    return <ReviewCard items={items} onEdit={(key) => onEdit(key)}
      onDelete={(key) => onDelete(key)} />;
  }

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Review Work Experience</div>
      </div>
      <div className="review-card-list">{renderReviewCardByValues()}</div>
      <div className="form-buttons">
        <Button
          className="app-btn-primary"
          type="primary"
          ghost
          onClick={() => onAdd()}
        >
          <PlusOutlined />
          Add Another Work Experience
        </Button>
        <Button
          className="app-btn-primary"
          type="primary"
          onClick={() => onNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
