import { Button, Checkbox, Form, Input, Select, DatePicker } from "antd";
import "./register-wizard-forms-customization.scss";
import { useEffect, useState } from "react";
import { Country } from "country-state-city";
import FormItemLabel from "antd/es/form/FormItemLabel";
import monthSelectOptions from "../../../shared/constants/month-select-options";
import { educationConfig } from "./onboarding-form.config";
import dayjs from "dayjs";
import { EducationFormData } from "../../../interfaces";

const { TextArea } = Input;

export interface EducationFormProps {
  formData?: EducationFormData;
  onSave?: (value: EducationFormData) => void;
}

export default function EducationForm({
  formData,
  onSave = () => { },
}: EducationFormProps) {
  const [currentlyStudying, setCurrentlyStudying] = useState<boolean>(
    formData?.currentlyStudying!
  );
  const [showFieldOfStudy, setShowFieldOfStudy] = useState<boolean>(true)
  const [fieldOfStudyRequired, setFieldOfStudyRequired] = useState<boolean>(true)
  const [allowCustomInput, setAllowCustomInput] = useState<boolean>(false)
  const [form] = Form.useForm();
  const countryOptions = Country.getAllCountries().map((country) => ({
    label: `${country.flag} ${country.name}`,
    value: country.name,
  }));

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        yearFrom: dayjs(formData.yearFrom),
        yearTo: formData?.yearTo ? dayjs(formData.yearTo) : "",
      });
    } else {
      form.resetFields();
    }
  }, [formData]);

  function handleFormSubmit(formValue: EducationFormData) {
    const newValues = {
      ...formValue,
      id: formData?.id,
      yearFrom: new Date(formValue.yearFrom).getFullYear().toString(),
      yearTo:
        !formValue.currentlyStudying && formValue.yearTo
          ? new Date(formValue.yearTo).getFullYear().toString()
          : undefined,
      monthTo: !formValue.currentlyStudying ? formValue.monthTo : undefined,
    };
    onSave(newValues);
  }
  const updateFieldVisbility = (value: string) => {
    if (value === 'High School Diploma' || value === "GED") {
      setShowFieldOfStudy(false);
    } else {
      setShowFieldOfStudy(true)
    }
    if (value === "Other") {
      setFieldOfStudyRequired(false)
      setAllowCustomInput(true)
      form.resetFields(["levelOfEducation"])
    } else {
      setFieldOfStudyRequired(true)
      setAllowCustomInput(false)
    }

  }
  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Add Education?</div>
        <div className="form-desc">Enter your Education here</div>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="education"
        onFinish={(values) => handleFormSubmit(values)}
      >
        <Form.Item
          label="Level of Education"
          required
          name="levelOfEducation"
          rules={educationConfig.levelOfEducation.rules}
        >
          {allowCustomInput === true ? (
            <Input size="large" value=" " placeholder="Enter Other Education" />
          ) : (
            <Select size="large" placeholder="Set Education Level" onChange={updateFieldVisbility}>
              <Select.Option value="High School Diploma">High School Diploma</Select.Option>
              <Select.Option value="GED">GED</Select.Option>
              <Select.Option value="Associate's Degree">Associate&apos;s Degree</Select.Option>
              <Select.Option value="Bachelor's Degree">Bachelor&apos;s Degree</Select.Option>
              <Select.Option value="Master's Degree">Master&apos;s Degree</Select.Option>
              <Select.Option value="Doctoral Degree">Doctoral Degree</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          )}
        </Form.Item>
        {showFieldOfStudy && (
          <Form.Item
            label="Field of Study"
            name="fieldOfStudy"
            rules={[{ required: fieldOfStudyRequired }]}
          >
            <Input size="large" placeholder="Field of Study" />
          </Form.Item>
        )}
        <div className="multiple-colon-form-item">

          <Form.Item
            label="School or university"
            name="school"
            rules={[{ required: true }]}
          >
            <Input size="large" placeholder="School or University" />
          </Form.Item>
        </div>
        <Form.Item label="Country" name="country" rules={[{ required: true }]}>
          <Select
            options={countryOptions}
            size="large"
            showSearch
            notFoundContent="No Match Found"
            placeholder="Select country"
          />
        </Form.Item>
        <FormItemLabel prefixCls="multiple-colon-form-item" label="From" />
        <div className="multiple-colon-form-item">
          <Form.Item name="monthFrom" rules={[{ required: true }]}>
            <Select
              options={monthSelectOptions}
              size="large"
              showSearch
              placeholder="Select month"
              notFoundContent="No Match Found"
            />
          </Form.Item>
          <Form.Item name="yearFrom" rules={[{ required: true }]}>
            <DatePicker picker="year" size="large" defaultOpenValue={dayjs()} />
          </Form.Item>
        </div>
        {currentlyStudying !== true ? (
          <>
            <FormItemLabel prefixCls="multiple-colon-form-item" label="To" />
            <div className="multiple-colon-form-item">
              <Form.Item name="monthTo">
                <Select
                  options={monthSelectOptions}
                  size="large"
                  showSearch
                  placeholder="Select month"
                  notFoundContent="No Match Found"
                />
              </Form.Item>
              <Form.Item name="yearTo">
                <DatePicker picker="year" size="large" />
              </Form.Item>
            </div>
          </>
        ) : null}
        <Form.Item name="currentlyStudying" valuePropName="checked">
          <Checkbox
            value={currentlyStudying}
            onChange={() => setCurrentlyStudying((prev) => !prev)}
          >
            Currently Studying
          </Checkbox>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button className="app-btn-primary" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
