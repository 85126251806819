import { Button, Form, FormListFieldData, Input } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  BehanceOutlined,
  HighlightFilled,
  PlusOutlined,
} from "@ant-design/icons";
import "./register-wizard-forms-customization.scss";
import SocialMediaPlatforms from "../../../shared/constants/social-media-platforms.enum";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import { useDispatch, useSelector } from "../../../store";

const DEFAULT_LINKS = [
  { link: "", type: "instagram" },
  { link: "", type: "facebook" },
  { link: "", type: "linkedin" },
  { link: "", type: "behance" },
];

export default function RelevantLinksForm(props: { inline: boolean }) {
  const { links = [] } = useSelector(selectOnboardingSlice);
  const staticFormItems = [
    { key: SocialMediaPlatforms.Instagram, icon: <InstagramOutlined /> },
    { key: SocialMediaPlatforms.Facebook, icon: <FacebookOutlined /> },
    { key: SocialMediaPlatforms.Linkedin, icon: <LinkedinOutlined /> },
    { key: SocialMediaPlatforms.Behance, icon: <BehanceOutlined /> },
    { key: "custom", icon: <HighlightFilled /> },
  ];
  const formInitialValue = links.length
    ? links
    : [...DEFAULT_LINKS, { link: "", type: "custom" }];
  const dispatch = useDispatch();

  function handleFormSubmit(formValue: {
    links: { link?: string; type: string }[];
  }) {
    dispatch(
      updateOnboarding({
        links: formValue.links.map((item, index) => ({
          link: item?.link,
          type: DEFAULT_LINKS?.[index]?.type || "custom",
        })),
        step: 6,
      })
    );
  }

  function renderFormItems(fields: FormListFieldData[], add: () => void) {
    return (
      <div className="relevant-link-list">
        {fields.map((field, index) => (
          <div key={field.key} className="relevant-link-item">
            <section className="relevant-link-item-icon">
              {index > staticFormItems.length - 1
                ? staticFormItems[staticFormItems.length - 1].icon
                : staticFormItems[index].icon}
            </section>
            <Form.Item key={field.key}>
              <Form.Item name={[field.name, "link"]}>
                <Input size="large" placeholder="link URL" />
              </Form.Item>
            </Form.Item>
          </div>
        ))}
        {props.inline !== true ? (
          <div>
            <Form.Item>
              <Button
                className="app-btn-primary"
                type="primary"
                ghost
                onClick={() => add()}
              >
                <PlusOutlined />
                Add Custom URL
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="app-btn-primary"
                type="primary"
                htmlType="submit"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="form-wrapper">
      {props.inline !== true ? (
        <div className="form-header">
          <div className="form-title">Add Relevant Links</div>
          <div className="form-desc">
            Add the Relevant links to strength your profile
          </div>
        </div>
      ) : null}
      <Form
        name="relevant-links-form"
        layout="vertical"
        onFinish={(values) => handleFormSubmit(values)}
      >
        <Form.List name="links" initialValue={formInitialValue}>
          {(fields, { add }) => renderFormItems(fields, add)}
        </Form.List>
      </Form>
    </div>
  );
}
