import { Flex, Spin, notification } from "antd";

import ProfileCard from "../../components/profiles/profiles-card";
import { useSelector } from "../../store";
import { selectProfilesSlice } from "../../store/slices";
import { useDeleteProfileMutation, useGetProfilesQuery, useCreateProfileMutation } from "../../store/apis";
import { useEffect } from "react";
import { mapError } from "../../shared/utils";

export default function Profiles() {
  const { items } = useSelector(selectProfilesSlice);
  const [deleteProfile, { error, isLoading: deleting }] = useDeleteProfileMutation();
  const { isLoading, refetch } = useGetProfilesQuery();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (error) {
      api.error({ message: mapError(error).message });
    }
  }, [error]);

  return (
    <Spin spinning={isLoading || deleting}>
      {contextHolder}
      <div>
        <Flex gap={"large"} wrap='wrap'>
          {items.map((profile, index) => (
            <div key={profile.id}>
              <ProfileCard profile={profile} index={index} onRefetchProfiles = {() => refetch()} onDelete={() => deleteProfile({ id: profile.id! })} />
            </div>
          ))}
          <div key={"new"}>
            <ProfileCard addNew={true} onRefetchProfiles = {() => refetch()} />
          </div>
        </Flex>
      </div>
    </Spin>
  );
}
