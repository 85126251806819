import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../payment-form/payment-form";
import { useMemo } from "react";

const price = 30000;

export default function HandlePayment() {
  const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string), []);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        currency: "usd",
        amount: price,
      }}
    >
      <PaymentForm price={price} name='Life Time'></PaymentForm>
    </Elements>
  );
}
