import { Alert, Avatar, Button, Card, Dropdown, Flex, Input, Space, Tag } from "antd";
import {
  MoreOutlined,
  UserOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "./profile-card.scss";
import { useNavigate } from "react-router-dom";
import { Profile } from "../../interfaces";
import { useCreateProfileMutation, useUpdateProfileMutation } from "../../store/apis";
import { useEffect, useState } from "react";
import { isProfileComplete } from "../../shared/utils/profile.utils";

export default function ProfileCard(props: {
  profile?: Profile;
  addNew?: boolean;
  index?: number;
  onDelete?: () => void;
  onRefetchProfiles?: () => void;
}) {
  const [createProfile, createProfileRes] = useCreateProfileMutation();
  const [updateProfile, updateProfileRes] = useUpdateProfileMutation();
  const { profile, addNew, index, onRefetchProfiles = () => { }, onDelete = () => { } } = props;
  const navigate = useNavigate();
  const [newProfileVisibility, setNewProfileVisibility] = useState(false);
  const [profileName, setProfileName] = useState('');
  // let profileName = '';
  const [updatedProfileName, setUpdatedProfileName] = useState(profile?.profileName);
  const [isFormSubmit, setFormSubmitStatus] = useState(false);
  const [isUpdateFormHasError, setUpdateFormHasError] = useState(false);
  let isMouseEvent = false;

  const handleEdit = async (id: string | number = "new") => {
    if (id === 'new' || !id) {
      await createProfile({ profileName: profileName })
    } else {
      navigate("/dashboard/profile/" + id);
    }
  };

  function getTagFromProfile(profile?: Profile) {
    if (!profile) {
      return <></>
    }
    return isProfileComplete(profile) ? <Tag color="green">Complete </Tag> : <Tag color="red">Incomplete</Tag>;
  }

  useEffect(() => {
    if (createProfileRes?.data && !createProfileRes?.error) {
      const profileId = createProfileRes.data.data.profileId;
      handleRefetch()
      setNewProfileVisibility(false)
    }
  }, [createProfileRes]);

  const handleRefetch = () => {
    onRefetchProfiles()
  }
  const handleDelete = () => {
    if (confirm("Are you sure you want to delete this profile?")) {
      onDelete();
    }
  };

  function onNewProfileClick() {
    setNewProfileVisibility(true);
  }

  function onProfileSubmit() {
    console.log(profileName)
    if (profileName) {
      handleEdit();
    } else {
      setFormSubmitStatus(true);
    }
  }

  function onProfileNameChange(event: any) {

    setProfileName(event.target.value);

  }

  function onUpdateProfileNameChange(event: any) {
    setUpdatedProfileName(event.target.value);
  }

  function onKeyDown(event: any) {
    isMouseEvent = true;
    if (event.key === 'Enter') {
      if (event.target.value.trim() !== '') {
        updateProfile({ id: profile?.id as string, profileName: updatedProfileName });
        event.target.blur();
      } else {
        setUpdateFormHasError(true);
        console.log('error')
      }
    } else if (event.key === 'Escape') {
      setUpdatedProfileName(profile?.profileName);
      setUpdateFormHasError(false);
      event.target.blur();
    }
    isMouseEvent = false;
  }

  function onBlur(event: any) {
    if (!isMouseEvent) {
      if (!updatedProfileName) {
        setUpdateFormHasError(true);
        event.currentTarget.focus();
      } else {
        setUpdatedProfileName(profile?.profileName);
        setUpdateFormHasError(false);
      }
    }
  }

  const profileView = () => (
    <div>
      <Flex justify="space-between">
        <div>
          {getTagFromProfile(profile)}
        </div>
        <div>
          <Dropdown
            menu={{
              items: [
                {
                  key: "edit",
                  label: "Edit",
                  onClick: () => handleEdit(profile?.id),
                },
                {
                  key: "remove",
                  danger: true,
                  label: "Remove",
                  onClick: () => handleDelete(),
                },
              ],
            }}
            placement="bottomRight"
          >
            <MoreOutlined
              style={{ fontSize: "22px" }}
              className="cursor-pointer"
            />
          </Dropdown>
        </div>
      </Flex>
      <Flex justify="center">
        <div>
          <div className="profile-picture">
            <Avatar
              size={120}
              icon={
                profile?.image ? <img src={profile?.image} /> : <UserOutlined />
              }
            />
          </div>
          <Input className={"title hover:bg-[#d3d3d3] " + (isUpdateFormHasError ? "border-1 border-rose-500" : "border-0")} placeholder={isUpdateFormHasError ? 'Can\'t be blank!' : 'Enter a profile name'} type="text" value={updatedProfileName} onChange={onUpdateProfileNameChange} onKeyDown={onKeyDown} onBlur={onBlur}></Input>
          <div className="subtitle">{profile?.subtitle}</div>
        </div>
      </Flex>
      <div className="action">
        <Button
          className="app-btn-primary-outline"
          htmlType="submit"
          onClick={() => {
            handleEdit(profile?.id);
          }}
        >
          {" "}
          Edit Profile{" "}
        </Button>
      </div>
    </div>
  );



  const addNewView = () =>
    <>
      {!newProfileVisibility &&
        <Flex
          className="add-new-profile"
          justify={"center"}
          onClick={() => onNewProfileClick()}
        >
          {" "}
          <PlusCircleOutlined className="add-icon" />{" "}
        </Flex>
      }
      {newProfileVisibility &&
        <Flex justify="center" align="space-between" gap='10px' style={{ height: '100%' }} vertical>
          <Flex justify="center" className="profile-picture">
            <Avatar
              size={120}
              icon={<UserOutlined />
              }
            />
          </Flex>
          <div className="title">New Profile</div>
          <div >
            <Space.Compact style={{ width: '100%' }}>
              <Input status={isFormSubmit && !profileName ? 'error' : ''} placeholder="Enter your profile name" onChange={onProfileNameChange} />
              <Button type="primary" onClick={() => onProfileSubmit()}>Save</Button>
            </Space.Compact>
            {isFormSubmit && !profileName && <span className="mt-3 text-xs text-red-600">Profile name is required!</span>}
          </div>
        </Flex>}
    </>;

  return (
    <Card className="profile-card">
      {/* Profile Card */}
      {!addNew && profile && profileView()}

      {/* Add New Card */}
      {addNew && addNewView()}
    </Card>
  );
}
