import "./register-wizard-forms-customization.scss";
import { useState } from "react";
import EducationReview from "./education-review";
import EducationForm from "./education-form";
import { useDispatch, useSelector } from "../../../store";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";

export default function Education() {
  const dispatch = useDispatch();
  const { userEducations = [] } = useSelector(selectOnboardingSlice);
  const [selectedIndex, setSelectedIndex] = useState<number>(userEducations.length === 0 ? 0 : -1);

  return selectedIndex > -1 ? (
    <EducationForm
      formData={userEducations[selectedIndex]}
      onSave={(values) => {
        dispatch(
          updateOnboarding({
            userEducations:
              !userEducations[selectedIndex] ? [...userEducations, values]
                : userEducations?.map((education, index) =>
                  selectedIndex === index ? values : education
                ),
          })
        );
        setSelectedIndex(-1);
      }}
    />
  ) : (
    <EducationReview
      onEdit={(key) => setSelectedIndex(key)}
      onAdd={() => setSelectedIndex(userEducations.length)}
      onNext={() => dispatch(updateOnboarding({ step: 3 }))}
    />
  );
}
