import {
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  PropsWithChildren,
} from "react";
import { PopupLoginOptions } from "@auth0/auth0-react";
import { Auth0Client } from "@auth0/auth0-spa-js";

import { Auth0ContextType } from "../interfaces";
import { useDispatch, useSelector } from "../store";
import {
  resetAuth,
  resetOnboarding,
  selectAuthSlice,
  updateAuth,
} from "../store/slices";
import PATH_DEFINITIONS from "../shared/constants/path-definitions";
import { AUTH0_PARAMS } from "../shared/constants";
import { useLazyGetUserQuery } from "../store/apis";
import { ERROR_CODE } from "../shared/enums";
import { LoadingView } from "../components/loading-view/loading-view";

const initialContextValues: Auth0ContextType = {
  isInitialized: false,
  user: undefined,
  logout: async () => {},
  login: async () => {},
};

export const AuthContext =
  createContext<Auth0ContextType>(initialContextValues);

let auth0Client: Auth0Client | null = null;
export { auth0Client };

export function AuthProvider({ children }: PropsWithChildren) {
  const dispatch = useDispatch();
  const [getUser] = useLazyGetUserQuery();
  const state = useSelector(selectAuthSlice);

  const initialize = useCallback(async () => {
    try {
      auth0Client = new Auth0Client(AUTH0_PARAMS);

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (!isAuthenticated) {
        dispatch(
          updateAuth({
            isInitialized: true,
            token: undefined,
            user: undefined,
            initialPath: PATH_DEFINITIONS.login,
          })
        );
        return;
      }

      const token = await auth0Client?.getTokenSilently();
      dispatch(updateAuth({ token }));
      const { data, error } = await getUser();
      if (
        error &&
        (error as any).data?.errorCode === ERROR_CODE.ONBOADING_REQUIRED
      ) {
        dispatch(
          updateAuth({
            isInitialized: true,
            token,
            user: undefined,
            initialPath: PATH_DEFINITIONS.onboarding,
          })
        );
      } else if (error && (error as any).status === 401) {
        logout();
      } else {
        dispatch(
          updateAuth({
            isInitialized: true,
            token,
            user: data,
            initialPath: PATH_DEFINITIONS.dashboard,
          })
        );
      }
    } catch (error) {
      dispatch(
        updateAuth({
          isInitialized: true,
          token: undefined,
          user: undefined,
          initialPath: PATH_DEFINITIONS.login,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(
    async (options?: PopupLoginOptions) => {
      await auth0Client?.loginWithPopup(options);
      const isAuthenticated = await auth0Client?.isAuthenticated();
      if (isAuthenticated) {
        const token = await auth0Client?.getTokenSilently();
        dispatch(updateAuth({ token }));
        const { data, error } = await getUser();
        if (
          error &&
          (error as any).data?.errorCode === ERROR_CODE.ONBOADING_REQUIRED
        ) {
          dispatch(
            updateAuth({
              user: undefined,
              initialPath: PATH_DEFINITIONS.onboarding,
            })
          );
        } else if (error && (error as any).status === 401) {
          logout();
        } else {
          dispatch(
            updateAuth({
              user: data,
              initialPath: PATH_DEFINITIONS.dashboard,
            })
          );
        }
      }
    },
    [dispatch]
  );

  const logout = useCallback(async () => {
    await auth0Client?.logout({
      logoutParams: {
        returnTo: `${window.location.origin}${PATH_DEFINITIONS.login}`,
      },
    });
    dispatch(resetAuth());
    dispatch(resetOnboarding());
  }, [dispatch]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      login,
      logout,
    }),
    [state, login, logout]
  );

  if (!state.isInitialized) {
    return <LoadingView />;
  }

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error("useAuth must be use inside AuthProvider");

  return context;
};
