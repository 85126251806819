import { Modal, ModalProps } from 'antd'
import React from 'react'
import { WorkExperienceFormData } from '../../interfaces'
import WorkExperienceForm from '../forms/onboarding/work-experience-form';

interface Props extends ModalProps {
  formData?: WorkExperienceFormData;
  onSave?: (values: WorkExperienceFormData) => void;
}

export const WorkExperienceFormModal = ({ formData, onSave = () => { }, ...rest }: Props) => {
  return (
    <Modal
      width={'60vw'}
      footer={null}
      {...rest}
    >
      <WorkExperienceForm formData={formData} onSave={onSave} />
    </Modal>
  )
}
