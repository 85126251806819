export const workExperieceConfig = {
    jobTitle: {
      rules: [{
        required: true, message: 'Job Title is required'
      }]
    },
  };
  
  export const educationConfig = {
    levelOfEducation: {
      rules: [{
        required: true, message: 'Level of education is required'
      }]
    },
  };
  
  export const licenceCeritificationConfig = {
    certificationName: {
      rules: [{
        required: true, message: 'Certification Name is required'
      }]
    },
  };