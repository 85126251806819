import { Button, Form, Input, Select } from 'antd';
import './register-wizard-forms-customization.scss';
import PhoneInput from 'antd-phone-input';
import { PersonalDetailsFormData } from '../../../interfaces';
import { selectOnboardingSlice, updateOnboarding } from '../../../store/slices';
import { useDispatch, useSelector } from '../../../store';
import { GENDERS } from '../../../shared/constants';



export default function PersonalDetailsForm() {
  const dispatch = useDispatch();
  const { personalDetails } = useSelector(selectOnboardingSlice);

  function handleFormSubmit(formData: PersonalDetailsFormData) {
    dispatch(updateOnboarding({ personalDetails: formData, step: 1 }));
  }

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">
          Personal Details
        </div>
        <div className="form-desc">
          Enter your Personal Details here
        </div>
      </div>
      <Form initialValues={personalDetails} onFinish={(values) => handleFormSubmit(values)}
        layout='vertical'>
        <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
          <Input size='large' placeholder="Enter your first name here" />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
          <Input size='large' placeholder="Enter your last name here" />
        </Form.Item>
        <Form.Item label="Email ID" name="mailId" rules={[{ required: true }]}>
          <Input size='large' placeholder="Enter your Mail ID" />
        </Form.Item>
        <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
          <PhoneInput country='us' enableSearch />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          rules={[{ required: true }]}
        >
          <Select
            options={GENDERS}
            placeholder="Select Gender"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Button className='app-btn-primary' type="primary" htmlType='submit'>Next</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
