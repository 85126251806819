import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Grid, Select, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../contexts";
import { useSelector } from "react-redux";
import { selectAuthSlice, selectProfilesSlice } from "../../../store/slices";
import { siteName_URL_MAP } from "./siteName_URL_Map";
import { JobUrlBuilder } from "./JobUrlBuilder";

interface IFilter {
  filterName: string;
  filterOptions: {
    name: string;
    href: string;
  }[];
}

export default function JobFilters({ formData, siteName }: { formData: Record<string, string>; siteName: string }) {
  const [filters, setFilters] = useState<IFilter[] | null>(null);
  const formRef = useRef<FormInstance<Record<string, string>>>(null);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector(selectAuthSlice);

  useEffect(() => {
    function messageHandler(e: MessageEvent) {
      try {
        if (e.origin != location.origin) return;
        switch (e.data.type) {
          case "filterData":
            setLoading(false);
            formRef.current?.resetFields();
            setFilters(e.data?.data.filterData);
            break;
        }
      } catch (e) {
        console.log(e);
      }
    }

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  function handleSubmit(formValues: Record<string, string>) {
    // only indeed logic for now
    if (!filters) return;
    try {
      setLoading(true);
      const baseURL = siteName_URL_MAP[siteName].applyBaseURL;
      const jobUrlBuilder = new JobUrlBuilder(baseURL, formData.jobTitle, formValues);
      window.postMessage(
        {
          type: "startFromApp",
          data: {
            url: jobUrlBuilder.fullUrl,
            filterObj: formData,
            token,
          },
        },
        "*"
      );
      setTimeout(() => {
        // if something breaks and response doesnt come in 10s, renable button anyway
        setLoading(false);
      }, 5000);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  if (!filters) return null;

  return (
    <>
      <div className='form-header'>
        <h4>Choose any one filter only (optional)</h4>
        <Button
          onClick={() => {
            formRef.current?.resetFields();
          }}
          type='primary'
          size='large'
          ghost
        >
          Reset
        </Button>
      </div>
      <div className='form'>
        <Form ref={formRef} onFinish={handleSubmit} layout='vertical' style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: ".5rem 2rem" }}>
          {filters?.map((filter) => (
            <Form.Item
              // radius is required or it will default to 25 Miles (Indeed)
              rules={[{ required: filter.filterName == "Radius", message: "Please select a radius" }]}
              key={filter.filterName}
              name={filter.filterName}
              style={{ fontWeight: 500, fontSize: "15px", textTransform: "capitalize" }}
              label={filter.filterName}
            >
              <Select
                options={[
                  // {
                  //   value: "",
                  //   label: "",
                  // },
                  ...filter.filterOptions.map((option) => ({
                    value: option.href,
                    label: option.name,
                  })),
                ]}
                size='large'
                placeholder={"Select " + filter.filterName}
              />
            </Form.Item>
          ))}
          <Button disabled={loading} type='primary' style={{ fontWeight: 600, gridColumn: "span 2" }} size='large' htmlType='submit' ghost block>
            {loading ? "Getting Jobs..." : "Apply now"}
          </Button>
        </Form>
      </div>
    </>
  );
}
