import { Button, Checkbox, DatePicker, Form, Input, Select, Tag } from "antd";
import "./register-wizard-forms-customization.scss";
import { useEffect, useState } from "react";
import typesOfEmployment from "../../../shared/constants/employment-types";
import FormItemLabel from "antd/es/form/FormItemLabel";
import monthSelectOptions from "../../../shared/constants/month-select-options";
import { workExperieceConfig } from "./onboarding-form.config";
import dayjs from "dayjs";
import { WorkExperienceFormData } from "../../../interfaces";
import workLocation from "../../../shared/constants/work-location";

const { TextArea } = Input;

export interface WorkExperienceFormProps {
  formData?: WorkExperienceFormData;
  onSave?: (formValues: WorkExperienceFormData) => void;
}

export default function WorkExperienceForm({
  formData,
  onSave = () => {},
}: WorkExperienceFormProps) {
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState<
    string[]
  >(formData?.typeOfEmployment || []);
  const [currentlyWorking, setCurrentlyWorking] = useState<boolean>(
    formData?.currentlyWorking!
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        startYear: dayjs(formData.startYear),
        endYear: formData?.endYear ? dayjs(formData.endYear) : "",
      });
    } else {
      form.resetFields();
    }
  }, [formData]);

  function handleNewData(formValue: WorkExperienceFormData) {
    const newData = {
      ...formValue,
      id: formData?.id,
      typeOfEmployment: selectedEmploymentTypes,
      startYear: new Date(formValue.startYear).getFullYear().toString(),
      endYear:
        !formValue.currentlyWorking && formValue.endYear
          ? new Date(formValue.endYear).getFullYear().toString()
          : undefined,
      endMonth: !formValue.currentlyWorking ? formValue.endMonth : undefined,
    };

    onSave(newData);
  }

  function handleTypesOfEmploymentChange(checked: boolean, type: string) {
    if (checked) {
      setSelectedEmploymentTypes((prev) => [...prev, type]);
    } else {
      setSelectedEmploymentTypes((prev) => prev.filter((p) => p !== type));
    }
  }

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Add Work Experience?</div>
        <div className="form-desc">Enter your work experience here</div>
      </div>
      <Form
        form={form}
        name="workExperience"
        onFinish={(values) => handleNewData(values)}
        layout="vertical"
      >
        <Form.Item
          label="Job Title"
          required
          name="jobTitle"
          rules={workExperieceConfig.jobTitle.rules}
        >
          <Input size="large" placeholder="Ex. Senior Project Manager" />
        </Form.Item>
        <div className="multiple-colon-form-item">
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ required: true }]}
          >
            <Input size="large" placeholder="Enter Company Name" />
          </Form.Item>
          <Form.Item
            label="Select Location"
            name="location"
            rules={[{ required: true }]}
          >
            <Select
              options={workLocation}
              size="large"
              showSearch
              placeholder="Select Location"
              notFoundContent="No Match Found"
            />
          </Form.Item>
        </div>
        <Form.Item label="Type of Employment">
          <section className="types-of-employment">
            {typesOfEmployment.map((type) => (
              <Tag.CheckableTag
                key={type}
                className="checkable-tag"
                checked={selectedEmploymentTypes.includes(type)}
                onChange={(checked) =>
                  handleTypesOfEmploymentChange(checked, type)
                }
              >
                {type}
              </Tag.CheckableTag>
            ))}
          </section>
        </Form.Item>
        <FormItemLabel
          prefixCls="multiple-colon-form-item"
          label="Start Date"
        />
        <div className="multiple-colon-form-item">
          <Form.Item name="startMonth" rules={[{ required: true }]}>
            <Select
              options={monthSelectOptions}
              size="large"
              showSearch
              placeholder="Select month"
              notFoundContent="No Match Found"
            />
          </Form.Item>
          <Form.Item name="startYear" rules={[{ required: true }]}>
            <DatePicker picker="year" size="large" defaultOpenValue={dayjs()} />
          </Form.Item>
        </div>
        {currentlyWorking !== true ? (
          <>
            <FormItemLabel
              prefixCls="multiple-colon-form-item"
              label="End Date"
            />
            <div className="multiple-colon-form-item">
              <Form.Item name="endMonth">
                <Select
                  options={monthSelectOptions}
                  size="large"
                  showSearch
                  placeholder="Select month"
                  notFoundContent="No Match Found"
                />
              </Form.Item>
              <Form.Item name="endYear">
                <DatePicker picker="year" size="large" />
              </Form.Item>
            </div>
          </>
        ) : null}
        <Form.Item name="currentlyWorking" valuePropName="checked">
          <Checkbox
            value={currentlyWorking}
            onChange={() => setCurrentlyWorking((prev) => !prev)}
          >
            Currently Working
          </Checkbox>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button className="app-btn-primary" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
