import "./index.scss";
import { App as AntApp } from "antd";
import { useEffect, useMemo } from "react";
import { RouterProvider } from "react-router-dom";

import { appRouter } from "./app-route";
import { AuthProvider } from "./contexts";
import { useSelector } from "./store";
import { selectAuthSlice } from "./store/slices";
import { NotificationHelper } from "./helpers";

function App() {
  const { initialPath } = useSelector(selectAuthSlice);
  const { notification } = AntApp.useApp();

  useEffect(() => {
    NotificationHelper.NotificationToastRef.current = notification;
  }, [notification]);

  const router = useMemo(() => {
    return appRouter(initialPath);
  }, [initialPath]);

  return (
    <div className="app-wrapper">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;
