import React from "react";
import type {
  ArgsProps,
  NotificationInstance,
} from "antd/es/notification/interface";

export class NotificationHelper {
  public static NotificationToastRef: React.MutableRefObject<NotificationInstance | null> =
    React.createRef();

  static success(args: ArgsProps) {
    this.NotificationToastRef.current?.success(args);
  }

  static error(args: ArgsProps) {
    this.NotificationToastRef.current?.error(args);
  }
}
