import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { AppState } from '..';
import { APP_ENV } from '../../shared/constants';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: APP_ENV.BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    const token = (getState() as AppState).auth?.token;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 401
    // let's add this scope when refresh_token is implemented
    // && (result?.error?.data as { errorCode: string })?.errorCode === 'token_expired'
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      // try {
      //   const token = await auth0Client?.getTokenSilently();
      //   if (token) {
      //     api.dispatch(updateAuth({ token }));
      //     result = await baseQuery(args, api, extraOptions);
      //   } else {
      //     api.dispatch(resetAuth());
      //   }
      // } finally {
      //   release();
      // }
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const fetchbase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQueryWithReauth(args, api, extraOptions);
  return result;
};

export const baseApi = (reducerPath: string) =>
  createApi({
    reducerPath,

    baseQuery: fetchbase,

    refetchOnFocus: true,

    refetchOnMountOrArgChange: true,

    tagTypes: [],

    endpoints: () => ({}),
  });
