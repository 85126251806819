import { MenuProps } from "antd";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";
import dashboardIcon from "../../assets/icons/menu/dashboard.svg";
import personIcon from "../../assets/icons/menu/person.svg";
import analyticsIcon from "../../assets/icons/menu/analytics.svg";
import starIcon from "../../assets/icons/menu/star.svg";
import letterIcon from "../../assets/icons/menu/letter.svg";
import sendEmailIcon from "../../assets/icons/menu/send-email.svg";
import checkInboxIcon from "../../assets/icons/menu/check-inbox.svg";
import messagingIcon from "../../assets/icons/menu/messaging.svg";
import webAppIcon from "../../assets/icons/menu/web-app.svg";
import jobSeekerIcon from "../../assets/icons/menu/job-seeker.svg";
import fingerprintAcceptedIcon from "../../assets/icons/menu/fingerprint-accepted.svg";
import userMenuIcon from "../../assets/icons/menu/user-menu.svg";
import postcardIcon from "../../assets/icons/menu/postcard.svg";
import paymentIcon from "../../assets/icons/menu/payment.svg";

const APP_MENU_ITEMS: MenuProps["items"] = [
  {
    label: "Menu",
    key: "menu",
    type: "group",
    className: "menu-group",
    children: [
      {
        key: PATH_DEFINITIONS.dashboard,
        label: "Auto Apply Dashboard",
        icon: <img src={dashboardIcon} />,
      },
      {
        key: PATH_DEFINITIONS.dashboardProfiles,
        label: "Profiles",
        icon: <img src={personIcon} />,
      },

      // {
      //   key: "analytics",
      //   label: "Analytics",
      //   icon: <img src={analyticsIcon} />,
      //   disabled: true,
      // },
      // {
      //   key: "resume-score",
      //   label: "Resume Score",
      //   icon: <img src={starIcon} />,
      //   disabled: true,
      // },
      // {
      //   key: "cover-letter",
      //   label: "Cover Letter",
      //   icon: <img src={postcardIcon} />,
      //   disabled: true,
      // },
      // {
      //   key: "email-inbox",
      //   label: "Email Inbox",
      //   icon: <img src={sendEmailIcon} />,
      //   disabled: true,
      // },
      // {
      //   key: "email-apply",
      //   label: "Email Apply",
      //   icon: <img src={checkInboxIcon} />,
      //   disabled: true,
      // },
      // {
      //   key: "application-board",
      //   label: "Application Board",
      //   icon: <img src={webAppIcon} />,
      //   disabled: true,
      // },
      {
        key: "subscription",
        label: "Manage Subscription",
        icon: <img src={paymentIcon} />,
        disabled: false,
      },
      {
        key: PATH_DEFINITIONS.contactUs,
        label: "Contact Us",
        icon: <img src={personIcon} />,
      },
    ],
  },
  // {
  //   label: "Job Portal",
  //   key: "job-portal",
  //   type: "group",
  //   className: "job-portal-group",
  //   children: [
  //     {
  //       key: "job-search",
  //       label: "Job Search",
  //       icon: <img src={jobSeekerIcon} />,
  //       disabled: true,
  //     },
  //     {
  //       key: "job-scan",
  //       label: "Job Scan",
  //       icon: <img src={fingerprintAcceptedIcon} />,
  //       disabled: true,
  //     },
  //     {
  //       key: "account",
  //       label: "Account",
  //       icon: <img src={userMenuIcon} />,
  //       disabled: true,
  //     },
  //   ],
  // },
  // {
  //   label: "Support",
  //   key: "support",
  //   type: "group",
  //   className: "support-group",
  //   children: [
  //     {
  //       key: "email",
  //       label: "Email",
  //       icon: <img src={letterIcon} />,
  //       disabled: true,
  //     },
  //     {
  //       key: "live-chat",
  //       label: "Live Chat",
  //       icon: <img src={messagingIcon} />,
  //       disabled: true,
  //     },
  //   ],
  // },
];

export default APP_MENU_ITEMS;
