import { AppError } from "../../interfaces";

const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again later.";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapError = (error: any): AppError => {
  let appError: AppError = {
    message: DEFAULT_ERROR_MESSAGE,
    status: 400,
  };

  if (typeof error?.data === "string") {
    appError = {
      status: error?.originalStatus || error?.status,
      message: error?.data,
    };
  } else if (typeof error?.data?.data === "string") {
    appError = {
      status: error?.originalStatus || error?.status,
      message: error?.data?.data,
    };
  } else if (error?.response && typeof error?.response?.data === "string") {
    return {
      ...error.response.data,
      message: error.response.data.message || error.response.data.error,
    };
  } else if (typeof error?.data?.message === "string") {
    appError = {
      status: error?.originalStatus || error?.status,
      message: error?.data?.message,
    };
  } else {
    appError = {
      message: error?.message || DEFAULT_ERROR_MESSAGE,
      status: 400,
    };
  }
  return appError;
};
