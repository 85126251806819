import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppState } from "..";
import { GetStatsRes, dashboardApi } from "../apis/dashboard.api";

export type DashboardStats = GetStatsRes;

export interface DashboardStatsContainer {
  data: DashboardStats[];
}

export interface DashboardStatsState {
  data?: DashboardStatsContainer;
  loading: boolean;
  error?: string | null;
}

const initialState: DashboardStatsState = {
  data: undefined,
  loading: false,
  error: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    startFetchingStats: (state: DashboardStatsState) => {
      state.loading = true;
      state.error = null;
    },
    statsFetchedSuccessfully: (state: DashboardStatsState, action: PayloadAction<DashboardStats[]>) => {
      console.log("stats fetched successfully and received");
      if (state.data) {
        state.data.data = action.payload;
      } else {
        state.data = { data: action.payload };
      }
      state.loading = false;
    },
    statsFetchingFailed: (state: DashboardStatsState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetJobs: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(dashboardApi.endpoints.getStats.matchFulfilled, (state, { payload }) => {
      if (state.data) {
        state.data.data = payload;
      } else {
        state.data = { data: payload };
      }
    });
  },
});

export const { startFetchingStats, statsFetchedSuccessfully, statsFetchingFailed, resetJobs } = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const selectDashboardSlice = (state: AppState) => state.dashboard;
