import "./subscription.scss";
import SubscriptionCard, { IPaymentCardProps } from "./subscriptionCard";
import FireIcon from "../../shared/svgs/fireIcon";
import TrophyIcon from "../../shared/svgs/trophyIcon";
import { APP_ENV } from "../../shared/constants";
import { useSelector } from "react-redux";
import { selectAuthSlice, selectRolesSlice } from "../../store/slices";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";
import { useEffect, useState } from "react";
import { useGetSubscriptionDetailQuery } from "../../store/apis";
import { hasActiveSubscription } from "../../shared/functions/subscription.functions";
import ManageSubscription from "./manage-subscription";

export default function Subscription() {
  const state = useSelector(selectAuthSlice);
  const roles = useSelector(selectRolesSlice);
 
  // const [hasActiveSubscription, setHasActiveSubscription] = useState(state.user?.roles);
  const [hasActivePlan, setHasActivePlan] = useState(false);
  useEffect(() => {
    
    if (roles?.length) {
      setHasActivePlan(hasActiveSubscription(roles));
    } else if (hasActivePlan) {
      setHasActivePlan(false);
    }
  });

  const navigateToCheckout = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault(); // Prevent default anchor behavior
    try {
      const res = await fetch(`${APP_ENV.BASE_URL}/payment/create-checkout-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: `Bearer ${state.token}` },
        body: JSON.stringify({
          trialPeriod: 7
        })
      });
      if (res.ok && res.body) {
        const data = await res.json();
        const redirectUrl = data.redirectUrl;
        if (redirectUrl) {
          window.location.href = redirectUrl; // Redirect the user to the new URL
        } else {
          console.error("Redirect URL not found");
        }
      } else {
        // Handle other statuses or errors
        console.error("Unexpected Error:", res.status);
      }
    } catch (err) {
      console.error("Unexpected Error:", err);
    }
  };

  const cards: IPaymentCardProps[] = [
    // {
    //   title: "Free Trial",
    //   price: "0",
    //   duration: "3 DAYS",
    //   points: { data: ["No Credit Card Required", "Up to 100 Applications", "Upgrade anytime", "Limited Job boards"], textColor: "gray-500" },
    //   href: "#",
    // },
    {
      title: "10x Plan",
      price: "29",
      duration: "/ PER MONTH",
      points: { data: ["Unlimited Applications", "Access all Job Boards", "Interview in 2 weeks (or we pay)✅", "24/7 Support Team"], textColor: "white" },
      onClick: navigateToCheckout,
      bgColor: "#6D00E7",
      textColor: "white",
      arrowFillColor: "white",
      icon: <FireIcon />,
    },
    {
      title: "Life time",
      price: "300",
      duration: "",
      href: PATH_DEFINITIONS.payment,
      points: { data: ["Unlimited Applications", "Access all Job Boards", "Interview forever", "24/7 Support Team"], textColor: "gray-500" },
      icon: <TrophyIcon />,
    },
    {
      title: "7 Day Free Trial",
      price: "Free",
      duration: "7 Days",
      points: { data: ["Unlimited Applications", "Access all Job Boards", "See How Easy It Is"], textColor: "gray-500" },
      icon: <TrophyIcon />,
    },
    
  ];

  return (
    <>
      <div className='flex flex-wrap justify-center gap-4'>
        {!hasActivePlan && cards.map((card) => (
          <div key={card.title} className='flex justify-center'>
            <SubscriptionCard cardData={card} key={card.title} />
          </div>
        ))}
      </div>
      {hasActivePlan && <ManageSubscription></ManageSubscription>}
    </>
  );
}
