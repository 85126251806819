import React from "react";
import checkMark from "../../assets/icons/checkmark.svg";
import { Button } from "antd";
import { Link } from "react-router-dom";

export default function PaymentSuccess() {
  return (
    <div className='w-full h-screen grid place-content-center text-center'>
      <div className='flex flex-col gap-4 bg-black bg-opacity-5 rounded px-32 py-16 shadow-black shadow-sm'>
        <img src={checkMark} width={72} className='block mx-auto text-green-500' />
        <div className='mb-2'>
          <h4 className='font-bold text-xl'>Thanks for your order</h4>
          <p className='font-semibold'> You have just completed your payment</p>
        </div>
        <Link to={"/dashboard"} className='bg-green-600 rounded hover:bg-green-800 w-fit mx-auto text-white px-4 py-2 text-center'>
          Go to dashboard
        </Link>
      </div>
    </div>
  );
}
