import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "antd";

// import Resume from "../../components/edit-profiles/resume";
import PersonalInformation from "../../components/edit-profiles/personal-information";
import EducationDetail from "../../components/edit-profiles/education-detail";
import LicenseAndCertificationDetail from "../../components/edit-profiles/license-certification-detail";
import WorkExperienceDetail from "../../components/edit-profiles/work-experience-detail";
import CoverLetter from "../../components/edit-profiles/cover-letter";
import { useSelector } from "../../store";
import { selectProfilesSlice } from "../../store/slices";
import { EditProfileFormProvider } from "../../contexts";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";
import { QuestionsSection } from "../../components/edit-profiles/questions-section";
import { NotificationHelper } from "../../helpers";

export default function EditProfile() {
  const { id } = useParams();
  const { items } = useSelector(selectProfilesSlice);
  const navigate = useNavigate();

  const profile = useMemo(() => {
    if (!id || id === "new") {
      return undefined;
    }

    return items?.find((item) => item?.id === id);
  }, [items, id]);

  return (
    <EditProfileFormProvider
      profile={profile}
      onEditSuccess={() => {
        NotificationHelper.success({ message: "Profile saved" });
        navigate(PATH_DEFINITIONS.dashboardProfiles);
      }}
    >
      <div className="flex flex-row justify-end">
        <Form>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-48"
          >
            Save
          </Button>
        </Form>
      </div>
      {/* <div>
        <Resume />
      </div> */}
      <div className="section mt-4">
        <PersonalInformation />
      </div>
      <div className="section mt-4">
        <WorkExperienceDetail />
      </div>
      <div className="section mt-4">
        <EducationDetail />
      </div>
      <div className="section mt-4">
        <LicenseAndCertificationDetail />
      </div>
      {/* <div className="section mt-4">
        <QuestionsSection />
      </div> */}
      <div className="section mt-4">
        <CoverLetter />
      </div>
    </EditProfileFormProvider>
  );
}
