import { Modal, ModalProps } from 'antd'
import React from 'react'
import { EducationFormData } from '../../interfaces'
import EducationForm from '../forms/onboarding/education-form';

interface Props extends ModalProps {
  formData?: EducationFormData;
  onSave?: (values: EducationFormData) => void;
}

export const EducationFormModal = ({ formData, onSave = () => { }, ...rest }: Props) => {
  return (
    <Modal
      width={'60vw'}
      footer={null}
      {...rest}
    >
      <EducationForm formData={formData} onSave={onSave} />
    </Modal>
  )
}
