import { Button, Flex } from "antd";
import "./edit-profile.scss";
import ReviewCard from "../review-card/review-card";
import { useMemo, useRef, useState } from "react";
import { Profile, WorkExperienceFormData } from "../../interfaces";
import { useEditProfileForm } from "../../contexts";
import { WorkExperienceFormModal } from "../modals";
import { useUpdateProfileMutation } from "../../store/apis";

export default function WorkExperienceDetail() {
  const { profile, updateProfileData } = useEditProfileForm();
  const data = useMemo(
    () => profile?.workExperiences || [],
    [profile?.workExperiences]
  );

  const [open, setOpen] = useState(false);
  const [editThisData, setEditThisData] = useState<WorkExperienceFormData>();
  const [updateProfile, updateProfileRes] = useUpdateProfileMutation();

  const itemIndex = useRef(0);

  const handleCancel = () => {
    setOpen(false);
    setEditThisData(undefined);
  };

  const showAddModal = () => {
    setEditThisData(undefined);
    setOpen(true);
  };

  const showEditModal = (data: WorkExperienceFormData) => {
    setEditThisData(data);
    setOpen(true);
  };

  const onSubmit = async (values: WorkExperienceFormData) => {
    let updatedWorkExperiences = [];
    if (!profile?.workExperiences?.length) {
      updatedWorkExperiences = [values];
    } else if (editThisData) {
      // udpate existing
      updatedWorkExperiences = profile?.workExperiences?.map((oItem, index) =>
        index === itemIndex.current ? values : oItem
      ) as WorkExperienceFormData[];

    } else {
      // add new one
      updatedWorkExperiences = [...(profile?.workExperiences || []), values];
    }
    updateProfileData({
      workExperiences: updatedWorkExperiences
    });
    await updateProfile({ id: profile?.id as string, workExperiences: updatedWorkExperiences })
    handleCancel();
  };

  const onDelete = (key: number) => {
    const newItems = profile?.workExperiences?.filter(
      (_, index) => index !== key
    );
    updateProfileData({ workExperiences: newItems });
  };

  return (
    <div className="edit-profile-wrapper">
      <div className="container">
        <Flex justify="space-between" align="center">
          <div className="title"> Work Experience </div>
          <Button
            className="app-btn-primary"
            type="primary"
            onClick={showAddModal}
          >
            Add Experience
          </Button>
        </Flex>
        <div style={{ marginTop: "18px" }}>
          {data?.map((item, index) => (
            <div key={index} className="space-vertical">
              <ReviewCard
                items={[
                  {
                    key: index,
                    title: item.jobTitle,
                    subtitles: [
                      item.companyName,
                      `${item.startMonth}/${item.startYear} - ${item.currentlyWorking
                        ? "Present"
                        : `${item.endMonth || ""}${item.endMonth ? "/" : ""
                        }` + `${item.endYear || ""}`
                      }`,
                    ],
                  },
                ]}
                onEdit={() => {
                  showEditModal(item);
                  itemIndex.current = index;
                }}
                onDelete={onDelete}
              />
            </div>
          ))}
        </div>
      </div>
      <WorkExperienceFormModal
        open={open}
        width={"60vw"}
        destroyOnClose
        onCancel={handleCancel}
        footer={null}
        onSave={onSubmit}
        formData={editThisData}
      />
    </div>
  );
}
