import { Button, Input } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import "./edit-profile.scss";
import { useEffect, useRef, useState } from "react";
import { TextAreaRef } from "antd/es/input/TextArea";
import { useEditProfileForm } from "../../contexts";

const { TextArea } = Input;

export default function CoverLetter() {
  const { profile, updateProfileData } = useEditProfileForm();
  const [editMode, setEditMode] = useState(false);
  const ref = useRef<TextAreaRef>(null);

  useEffect(() => {
    if (editMode) {
      ref?.current?.focus();
    }
  }, [editMode]);

  return (
    <div className="edit-profile-wrapper">
      <div className="container cover-letter-section">
        <div className="title"> Cover Letter </div>
        <div className="space-vertical relative">
          <TextArea
            ref={ref}
            disabled={!editMode}
            rows={4}
            onBlur={() => setEditMode(false)}
            value={profile?.coverLetter || ""}
            onChange={(e) => updateProfileData({ coverLetter: e.target.value })}
          />
          <div className="absolute top-0 right-4 flex flex-row gap-4">
            {!editMode && (
              <Button
                type="link"
                className="p-0"
                onClick={() => setEditMode(true)}
              >
                <EditFilled />
              </Button>
            )}
            <Button
              type="link"
              className="p-0"
              onClick={() => updateProfileData({ coverLetter: "" })}
            >
              <DeleteFilled />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
