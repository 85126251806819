import "./auto-apply.scss";
import "swiper/css/bundle";
import AutoApplyCard from "../../components/auto-apply-card/auto-apply-card";
import AutoApplyCover from "../../assets/images/auto-apply-cover.png";
import { Button } from "antd";
import PromoCard from "../../components/promo-card/promo-card";
import promoCardConfigs from "./promo-card-config";
import autoApplyCardConfigs from "./auto-apply-card-config";
import Chart from "chart.js/auto";
// import { Doughnut, Line } from 'react-chartjs-2';
// import { doughnutChartOptions, lineChartOptions } from './chart-presets';
import { useNavigate } from "react-router-dom";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import ReminderCard from "../../components/reminder-card/reminder-card";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "../../store";
import { selectDashboardSlice, selectAuthSlice, selectRolesSlice  } from "../../store/slices";
import { DashboardStats, DashboardStatsState } from "../../store/slices/dashboard.slice";
import { useGetStatsQuery } from "../../store/apis";
import { useEffect, useState } from "react";

Chart.register();
export interface recentlyAppliedData {
  data: [];
}
export default function AutoApply() {
  const navigate = useNavigate();

  const promoCards = promoCardConfigs;
  const autoApplyCards = autoApplyCardConfigs;
  const role = useSelector(selectRolesSlice)
  // -- Fetch user information --
  const { user } = useSelector(selectAuthSlice);
  const [disabledAutoApply, setdisabledAutoApply] = useState(true);
  const [roleName, setRoleName] = useState('No Subscription');
  const [dailyLimit, setDailyLimit] = useState('0/0');
  const [dailyLimitSubtext, setDailyLimitSubtext] = useState('Subscribe now to start applying to jobs automatically');
  // -- Extract the applied counts for each platform --
  const platforms: { [platform: string]: number } = user?.applied;

  // -- Calculate the total number of applications for each platform --
  let totalJobsApplied = 0;

  // -- Iterate over each platform and sum up the number --
  for (const platform in platforms) {
    totalJobsApplied += platforms[platform];
  }

  useEffect(() => {
    console.log(role)
    if (role?.length) {
      setRoleName(role[0].name)
      setdisabledAutoApply(false);
      setDailyLimit('Unlimited')
      setDailyLimitSubtext('Apply To As Many Jobs As You Need')
    } 
  });

  // -- Update the right count --
  promoCards[2].text = String(totalJobsApplied);

  // -- Update Plan Details ---
  promoCards[1].text = String(roleName)

  // -- Update Daily Limit Details ---
  promoCards[0].text = dailyLimit
  promoCards[0].subtext = dailyLimitSubtext

  // const doughnutChartData = {
  //   labels: ['Linkedin', 'İndeed', 'Dice', 'Glassdoor'],
  //   datasets: [
  //     {
  //       label: 'number of applications',
  //       data: [12, 19, 3, 5],
  //       backgroundColor: [
  //         'rgba(255, 99, 132, 1)',
  //         'rgba(54, 162, 235, 1)',
  //         'rgba(255, 206, 86, 1)',
  //         'rgba(75, 192, 192, 1)',
  //       ],
  //       borderWidth: 0,
  //     },
  //   ],
  // };
  const lineChartData = {
    labels: ["23 Jan", "24 Jan", "25 Jan", "26 Jan"],
    datasets: [
      {
        label: "Applied Jobs",
        data: [10, 20, 30, 10],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const { data, error, isLoading } = useGetStatsQuery();
  // console.log({ dataFromQuery: data, error, isLoading });
  const stats: DashboardStatsState = useSelector(selectDashboardSlice);
  const recentlyAppliedDataContainer: any = stats;
  const recentlyAppliedData: any = recentlyAppliedDataContainer?.data?.data?.data;
  const reminderCards = recentlyAppliedData?.[0]
    ? recentlyAppliedData?.map((stat: DashboardStats) => ({
      title: stat.listingTitle,
      subtitle: stat.companyName,
      date: new Date(stat.createdAt).toLocaleString("en-US", { weekday: "short", day: "numeric", month: "long" }),
      jobUrl: stat.jobUrl,
      time: new Date(stat.createdAt).toLocaleString("en-US", { hour: "numeric", hour12: true, minute: "numeric" }),
      backgroundColor: "#F8E8FF",
      avatar: "ID",
    }))
    : [
      {
        title: "You have not applied to any jobs yet",
        subtitle: "As you apply to jobs, you will see history here",
        date: null,
        time: null,
        jobUrl: null,
        backgroundColor: "#F8E8FF",
        avatar: "ID",
      },
    ];

  //group into chunks of 10
  const chunkSize = 10;
  const reminderChunks = [];
  for (let i = 0; i < reminderCards.length; i += chunkSize) {
    reminderChunks.push(reminderCards.slice(i, i + chunkSize));
  }

  function handleAutoApplyCardButtonClick(formKey: string) {
    const url = `${PATH_DEFINITIONS.autoApplyForm}/${formKey}`;
    navigate(url, {
      state: {
        formKey,
      },
    });
  }

  return (
    <div className='auto-apply-page-wrapper'>
      <div className='promo-cards'>
        {promoCards.map((promoCard, i) => (
          <PromoCard
            key={i}
            header={promoCard.header}
            icon={promoCard.icon}
            text={promoCard.text}
            subtext={promoCard.subtext}
            cardBackgroundColor={promoCard.cardBackgroundColor}
           
          />
        ))}
      </div>
      <div className='flex-container'>
        <div className='auto-apply-cards'>
          <section className='large-card'>
            <img src={AutoApplyCover} alt='' />
            <div className='card-section'>
              <div className='slogan'>Be in the spotlight with Nextrole</div>
              {/* <Button size='large' block>
                Auto Apply
              </Button> */}
            </div>
          </section>
          <div className='auto-apply-card-list'>
            {autoApplyCards.map((autoApplyCard, i) => (
              <div key={i} className='auto-apply-card-wrapper'>
                <AutoApplyCard
                  formKey={autoApplyCard.formKey}
                  logo={autoApplyCard.logo}
                  logoExpanded={autoApplyCard.logoExpanded}
                  bullets={autoApplyCard.bullets}
                  buttonText='Auto Apply'
                  cardButtonClick={(key) => handleAutoApplyCardButtonClick(key)}
                  disabledAutoApply={disabledAutoApply}
                  
                />
              </div>
            ))}
          </div>
        </div>
        <section className='activity-graphs'>
          {/* <div className="doughnut-chart-wrapper">
            <div className="doughnut-chart">
              <Doughnut options={doughnutChartOptions} data={doughnutChartData} />
            </div>
            <a><b>{'View All Jobs Applied >'}</b></a>
          </div>
          <hr /> */}
          {/* <div className="section-title">
            Last 4 Days
          </div>
          <div className="line-chart-wrapper">

            <Line options={lineChartOptions} data={lineChartData} />
          </div> */}
          <div className='interview-reminder'>
            <Swiper className='swiper-container' slidesPerView={1}>
              <span slot='container-start'>
                <InterviewReminderHeader />
              </span>
              {reminderChunks.map((chunk, index) => (
                <SwiperSlide key={index}>
                  <div className='reminder-card-container'>
                    {chunk.map((reminderCard: any, i: any) => (
                      <ReminderCard key={i} {...reminderCard} />
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <a>
              <b>{"View All >"}</b>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}

/* seperate this function as a component to reach useSwiper hook */
function InterviewReminderHeader() {
  const swiper = useSwiper();

  return (
    <div className='section-header'>
      <div className='section-title'>Recently Applied To Jobs</div>
      <div className='carousel-controllers'>
        <Button type='link' onClick={() => swiper.slidePrev()}>
          <LeftOutlined />
        </Button>
        <Button type='link' onClick={() => swiper.slideNext()}>
          <RightOutlined />
        </Button>
      </div>
    </div>
  );
}
