import { Avatar, Flex } from "antd";
import "./reminder-card.scss";

interface ReminderCardProps {
  avatar: string;
  title: string;
  subtitle: string;
  jobUrl: string;
  date: string;
  time: string;
  backgroundColor: string;
}

export default function ReminderCard(props: ReminderCardProps) {
  return (
    <section className='reminder-card' style={{ backgroundColor: props.backgroundColor }}>
      <Flex justify='space-between' align='center'>
        <div className='section-left'>
          <Avatar size='large' src={props.avatar}>
            {props.avatar}
          </Avatar>
          <div>
            <div className='title'>
              {props.jobUrl ? (
                <a href={props.jobUrl} target='_blank' rel='noreferrer' className='anchor'>
                  {props.title}
                </a>
              ) : (
                <div>{props.title}</div>
              )}
            </div>
            <div className=''>{props.subtitle}</div>
          </div>
        </div>
        <div>
          <div className='section-text'>{props.date}</div>
          <div className='section-text'>{props.time}</div>
        </div>
      </Flex>
    </section>
  );
}
