import React from 'react';
import { Form, Input, Button, Typography, Row, Col, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title, Text } = Typography;

const ContactUs: React.FC = () => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                // Handle form submission here, e.g., send data to your API
                message.success('Your message has been sent!');
                form.resetFields(); // Reset form after submission
            })
            .catch((info) => {
                console.log('Validation Failed:', info);
            });
    };

    return (
        <Row justify="center" style={{ marginTop: '50px' }}>
            <Col xs={24} sm={18} md={12} lg={8}>
                <Title level={2} style={{ textAlign: 'center' }}>Contact Us</Title>
                {/* <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                        <Input placeholder="Your name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}
                    >
                        <Input placeholder="Your email address" />
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label="Message"
                        rules={[{ required: true, message: 'Please enter your message' }]}
                    >
                        <TextArea rows={4} placeholder="Your message" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Send Message
                        </Button>
                    </Form.Item>
                </Form> */}
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Text> You Can Reach Us Directly At </Text>
                    <br />
                    <Text >
                        <MailOutlined /> admin@nextrole.ai
                    </Text>
                </div>
            </Col>
        </Row>
    );
};

export default ContactUs;