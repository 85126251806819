export class JobUrlBuilder {
  public fullUrl = "";
  public host = "";
  constructor(public baseURL: string, public jobTitle: string, public formValues: Record<string, string>) {
    this.host = new URL(baseURL).host;
    switch (this.host) {
      case "indeed.com":
        this.fullUrl = this.buildIndeedUrl();
        break;
      case "www.dice.com":
        this.fullUrl = this.buildDiceUrl();
        break;
      case "www.ziprecruiter.com":
        this.fullUrl = this.buildZipRecruiterURL();
    }
  }

  private buildIndeedUrl() {
    const searchParams = new URL(this.baseURL).searchParams;
    const sc = {} as Record<string, string>;
    Object.entries(this.formValues).forEach(([key, href]) => {
      if (!href) return;
      if (key == "Pay") {
        const salary = href.split("-")[2];
        this.jobTitle += " " + salary;
      } else {
        Array.from(new URL(href).searchParams).map(([key, value]) => {
          if (key == "sc") {
            const tag = value.split(":")[0];
            if (tag in sc) {
              sc[tag] = sc[tag].replace(";", "") + value.split(":")[1].replace(";", "");
            } else {
              sc[tag] = value.split(":")[1].replace(";", "");
            }
          } else {
            searchParams.set(key, value);
          }
        });
      }
    });
    const scQS = Object.keys(sc)
      .map((key, i) => {
        let k = key;
        if (i > 0) k = key.replace("0", "");
        return k + ":" + sc[key];
      })
      .join(",");
    if (scQS) searchParams.set("sc", scQS + ";");
    searchParams.set("q", this.jobTitle);
    const fullUrl = this.baseURL + searchParams.toString();
    return fullUrl;
  }

  private buildDiceUrl() {
    const searchParams = new URL(this.baseURL).searchParams;
    Object.entries(this.formValues).map(([key, href]) => {
      if (!href) return;
      Array.from(new URL(href).searchParams).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    });
    searchParams.set("q", this.jobTitle);
    const fullUrl = this.baseURL + searchParams.toString();
    return fullUrl;
  }

  private buildZipRecruiterURL() {
    const searchParams = new URL(this.baseURL).searchParams;
    Object.entries(this.formValues).map(([key, href]) => {
      if (!href) return;
      Array.from(new URL(href).searchParams).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    });
    searchParams.set("search", this.jobTitle);
    const fullUrl = this.baseURL + searchParams.toString();
    return fullUrl;
  }
}
