import "./login.scss";
import { Button, Divider, Image } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import googleLogo from "../../assets/icons/google.png";
import linkedinLogo from "../../assets/icons/linkedin.png";
import facebookLogo from "../../assets/icons/facebook.png";
import appLogo from "../../assets/icons/app-logo.svg";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";
import { resetOnboarding, selectAuthSlice } from "../../store/slices";
import { dispatch, useSelector } from "../../store";
import { useAuth } from "../../contexts";

export default function Login() {
  const { login } = useAuth();
  const { initialPath } = useSelector(selectAuthSlice);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onLogin = async ({
    isSignup = false,
    connection,
  }: {
    isSignup: boolean;
    connection?: string;
  }) => {
    try {
      dispatch(resetOnboarding());
      setLoading(true);
      await login({
        authorizationParams: {
          screen_hint: isSignup ? "signup" : "login",
          connection,
        },
      });
    } catch (error: any) {
      console.log("====", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialPath !== PATH_DEFINITIONS.login) {
      navigate(initialPath);
    }
  }, [initialPath]);

  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <section className="login-section">
          <Image
            width="50%"
            src={appLogo}
            alt="App logo"
            style={{ marginBottom: 20 }}
          ></Image>
          <div className="login-titles">
            <span className="section-title">Login to your Account</span>
            <span className="section-text">
              {"Don't have an account yet?"}{" "}
              <Link
                to={PATH_DEFINITIONS.login}
                onClick={(e) => {
                  e.preventDefault();
                  onLogin({ isSignup: true });
                }}
              >
                Register here
              </Link>
            </span>
          </div>
          <Button
            className="app-btn-primary"
            type="primary"
            loading={loading}
            onClick={() => onLogin({ isSignup: false })}
          >
            Login with Email
          </Button>
        </section>
        <Divider
          style={{
            borderBlockColor: "#000000",
            fontSize: "20px",
            color: "#686868",
            padding: "10px 50px",
          }}
        >
          OR
        </Divider>
        <section className="third-party-login-section">
          <div className="login-btn-list">
            <Button
              className="third-party-btn"
              size="large"
              onClick={() => {
                onLogin({ isSignup: true, connection: "google-oauth2" });
              }}
            >
              <Image width={20} src={googleLogo} alt="Google logo"></Image>
              <div className="btn-text">
                <span>Continue with Google</span>
              </div>
            </Button>
            {/* <Button className="third-party-btn" size="large">
              <Image width={20} src={facebookLogo} alt="Facebook logo"></Image>
              <div className="btn-text">
                <span>Continue with Facebook</span>
              </div>
            </Button> */}
            {/* <Button className="third-party-btn" size="large">
              <Image width={20} src={linkedinLogo} alt="Linkedin logo"></Image>
              <div className="btn-text">
                <span>Continue with Linkedin</span>
              </div>
            </Button> */}
          </div>
        </section>
      </div>
    </div>
  );
}
