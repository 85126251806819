import { Avatar, Dropdown, MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from '../../store';
import { resetAuth } from '../../store/slices';
import { useNavigate } from 'react-router-dom';
import PATH_DEFINITIONS from '../../shared/constants/path-definitions';
import { useAuth } from '../../contexts';

export default function ProfileDropdown() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { logout } = useAuth();

    const onLogout = async () => {
        await logout();
        dispatch(resetAuth());
        navigate(PATH_DEFINITIONS.login);
    }

    const dropdownMenuItems: MenuProps['items'] = [
        {
            key: 'logout',
            danger: true,
            label: 'Log Out',
            onClick: onLogout,
          },
      ];
  
    return (
    <Dropdown menu={{ items: dropdownMenuItems }} placement="bottomRight">
        <Avatar size={40} icon={<UserOutlined />} className='cursor-pointer' />
    </Dropdown>
    );
}
