import { Button, Image } from 'antd';
import './upload-cv.scss';
import jobSeekerImg from '../../assets/images/job-seeker.png';
import FileUpload from '../../components/file-upload/file-upload';

export default function UploadCVPage() {
  return (
    <div className="upload-cv-wrapper">
      <section className="upload-cv-section">
        <div className="section-header">
          <Image src={jobSeekerImg} />
        </div>
        <div className="section-body">
          <div className="section-title">
            Upload your CV
          </div>
          <div className="upload-component">
            <FileUpload />
          </div>
          <div className="section-text">
            By continuing, you agree to receive job opportunities from Indeed.
          </div>
          <div className="section-buttons">
            <div className="btn-layout-top">
              <div className="next-button">
                <Button size='large' type='primary'>Next</Button>
              </div>
              <div className="build-your-cv-button">
                <Button size='large' type='primary' ghost>Build your CV</Button>
              </div>
            </div>
            <div className="skip-btn">
              <Button size='large'>Skip for now</Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
