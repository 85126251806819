import { User } from '../../interfaces';
import { baseApi } from './base.api';

export const onboardingApi = baseApi('onboardingApi').injectEndpoints({
  overrideExisting: false,

  endpoints: (build) => ({
    onboarding: build.mutation<OnboardingRes, OnboardingReq>({
      query: (body) => ({
        url: `/user/onboarding`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useOnboardingMutation } = onboardingApi;

export type OnboardingRes = User;

export type OnboardingReq = {
  email?: string;
  name?: string;
  phone?: string;
  gender?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  relevantLinks?: {link: string; name: string}[];
  licenseCertifications?: any[];
  workExperiences?: any[];
  userEducations?: any[];
};
