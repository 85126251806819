import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { Profile } from "../../interfaces";
import { profilesApi } from "../apis";

export interface ProfilesState {
  items: Profile[];
  loading: boolean;
}

const initialState: ProfilesState = {
  items: [],
  loading: false,
};

/**
 * Slice
 */
export const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    updateProfiles: (state, action: PayloadAction<Partial<ProfilesState>>) => {
      return { ...state, ...action.payload };
    },
    resetProfiles: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(profilesApi.endpoints.getProfiles.matchFulfilled, (state, { payload }) => {
      state.items = payload.data || [];
    });
    builder.addMatcher(profilesApi.endpoints.updateProfile.matchFulfilled, (state, { payload }) => {
      state.items = state.items?.map((item) => (item.id === payload?.data?.id ? payload.data : item));
    });
    builder.addMatcher(profilesApi.endpoints.deleteProfile.matchFulfilled, (state, { meta }) => {
      state.items = state.items?.filter((item) => item.id !== meta.arg.originalArgs.id);
    });
  }
});

/**
 * Actions
 */
export const { updateProfiles, resetProfiles } = profilesSlice.actions;

/**
 * Selectors
 */
export const selectProfilesSlice = (state: AppState) => state.profiles;
