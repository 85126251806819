import { baseApi } from "./base.api";

export const dashboardApi = baseApi("dashboardApi").injectEndpoints({
  overrideExisting: false,

  endpoints: (build) => ({
    getStats: build.query<GetStatsRes[], void>({
      query: (body) => ({
        url: `/stats`,
        method: "GET",
        body,
      }),
    }),
  }),
});

export const { useGetStatsQuery } = dashboardApi;

export type GetStatsRes = {
  listingTitle: string;
  companyName: string;
  jobUrl: string;
  searchTitle: string;
  id: string;
  createdAt: string;
  updatedAt: string;
};
