import { Button, Flex } from "antd";
import "./edit-profile.scss";
import ReviewCard from "../review-card/review-card";
import { useMemo, useRef, useState } from "react";
import { LicenceAndCertificationFormData } from "../../interfaces";
import { useEditProfileForm } from "../../contexts";
import { LicenseCertificatesFormModal } from "../modals";

export default function LicenseAndCertificationDetail() {
  const { profile, updateProfileData } = useEditProfileForm();
  const data = useMemo(
    () => profile?.licenseCertifications || [],
    [profile?.licenseCertifications]
  );

  const [open, setOpen] = useState(false);
  const [editThisData, setEditThisData] =
    useState<LicenceAndCertificationFormData>();
  const itemIndex = useRef(0);

  const handleCancel = () => {
    setOpen(false);
    setEditThisData(undefined);
  };

  const showAddModal = () => {
    setEditThisData(undefined);
    setOpen(true);
  };

  const showEditModal = (data: LicenceAndCertificationFormData) => {
    setEditThisData(data);
    setOpen(true);
  };

  const onSubmit = (values: LicenceAndCertificationFormData) => {
    if (!profile?.licenseCertifications?.length) {
      updateProfileData({ licenseCertifications: [values] });
      handleCancel();
      return;
    }

    if (editThisData) {
      // udpate existing
      updateProfileData({
        licenseCertifications: profile?.licenseCertifications?.map(
          (oItem, index) => (index === itemIndex.current ? values : oItem)
        ),
      });
    } else {
      // add new one
      updateProfileData({
        licenseCertifications: [
          ...(profile?.licenseCertifications || []),
          values,
        ],
      });
    }
    handleCancel();
  };

  const onDelete = (key: number) => {
    const newItems = profile?.licenseCertifications?.filter(
      (_, index) => index !== key
    );
    updateProfileData({ licenseCertifications: newItems });
  };

  return (
    <div className="edit-profile-wrapper">
      <div className="container">
        <Flex justify="space-between" align="center">
          <div className="title">License and Certification </div>
          <Button
            className="app-btn-primary"
            type="primary"
            onClick={showAddModal}
          >
            Add License and Certification{" "}
          </Button>
        </Flex>
        <div style={{ marginTop: "18px" }}>
          {data?.map((item, index) => (
            <div key={index} className="space-vertical">
              <ReviewCard
                items={[
                  {
                    key: index,
                    title: item.certificationName,
                    subtitles: [
                      item.issuedBy,
                      `${item.issueDateMonth}/${item.issueDateYear} - ${
                        item.lifetimeAccess
                          ? "Lifetime Access"
                          : `${item.expirationDateMonth || ""}${
                              item.expirationDateMonth ? "/" : ""
                            }` + `${item.expirationDateYear || ""}`
                      }`,
                    ],
                  },
                ]}
                onEdit={() => {
                  showEditModal(item);
                  itemIndex.current = index;
                }}
                onDelete={onDelete}
              />
            </div>
          ))}
        </div>
      </div>
      <LicenseCertificatesFormModal
        open={open}
        width={"60vw"}
        destroyOnClose
        onCancel={handleCancel}
        footer={null}
        onSave={onSubmit}
        formData={editThisData}
      />
    </div>
  );
}
