import { Middleware } from 'redux';

import { authApi, onboardingApi, profilesApi, dashboardApi, paymentApi } from './apis';


export const appMiddleware: Middleware<unknown>[] = [
  authApi.middleware,
  onboardingApi.middleware,
  profilesApi.middleware,
  dashboardApi.middleware,
  paymentApi.middleware
];
