const PATH_DEFINITIONS = {
  login: "/login",
  register: "/register",
  onboarding: "/onboarding",
  uploadCV: "/upload-cv",
  paymentSuccess: "/payment/success",
  paymentFail: "/payment/fail",
  dashboard: "/dashboard",
  dashboardProfiles: "/dashboard/profiles",
  dashboardEditProfile: "/dashboard/profile/:id",
  autoApply: "/dashboard/auto-apply",
  autoApplyForm: "/dashboard/auto-apply-form",
  payment: "/dashboard/payment",
  subscription: "/dashboard/subscription",
  contactUs: "/dashboard/contact-us"
};

export default PATH_DEFINITIONS;
