import LinkedinLogo from "../../assets/icons/linkedin.png";
import LinkedinExpandedLogo from "../../assets/icons/linkedin-expanded.svg";
import IndeedLogo from "../../assets/icons/GroupIndeedLogo.jpg";
import IndeedExpandedLogo from "../../assets/icons/IndeedExpanded.png";
import DiceLogoExpanded from "../../assets/icons/DiceExpanded.png";
import DiceLogo from "../../assets/icons/dice-fav-icon.png";
import ZipRecuiterLogo from "../../assets/icons/ZipRecruiter.png";
import ZipRecuiterExtended from "../../assets/icons/ZipRecruiterExtended.svg";

const autoApplyCardConfigs = [
  {
    formKey: "indeed",
    logo: IndeedLogo,
    logoExpanded: IndeedExpandedLogo,
    bullets: [],
  },
  {
    formKey: "dice",
    logo: DiceLogo,
    logoExpanded: DiceLogoExpanded,
    bullets: [
      // 'lorem ipsum dolor',
      // 'linkedin is best'
    ],
  },
  {
    formKey: "ziprecruiter",
    logo: ZipRecuiterLogo,
    logoExpanded: ZipRecuiterExtended,
    bullets: [],
  },
  // {
  //   formKey: 'linkedin3',
  //   logo: LinkedinLogo,
  //   logoExpanded: LinkedinExpandedLogo,
  //   bullets: [
  //     'lorem ipsum dolor',
  //     'linkedin is best'
  //   ]
  // }
];

export default autoApplyCardConfigs;
