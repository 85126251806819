import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./register-wizard-forms-customization.scss";
import { useEffect, useState } from "react";
import monthSelectOptions from "../../../shared/constants/month-select-options";
import FormItemLabel from "antd/es/form/FormItemLabel";
import dayjs from "dayjs";
import { licenceCeritificationConfig } from "./onboarding-form.config";
import { LicenceAndCertificationFormData } from "../../../interfaces";

const { TextArea } = Input;

export interface LicenceAndCertificationFormProps {
  formData?: LicenceAndCertificationFormData;
  onSave?: (value: LicenceAndCertificationFormData) => void;
}

export default function LicenceAndCertificationForm({
  formData,
  onSave = () => {},
}: LicenceAndCertificationFormProps) {
  const [lifetimeAccess, setLifetimeAccess] = useState<boolean>(
    formData?.lifetimeAccess!
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        ...formData,
        issueDateYear: dayjs(formData.issueDateYear),
        expirationDateYear: formData?.expirationDateYear
          ? dayjs(formData.expirationDateYear)
          : "",
      });
    } else {
      form.resetFields();
    }
  }, [formData]);

  function handleNewCertificationData(
    formValue: LicenceAndCertificationFormData
  ) {
    const newValue = {
      ...formValue,
      id: formData?.id,
      issueDateYear: new Date(formValue.issueDateYear).getFullYear().toString(),
      expirationDateYear:
        !formValue.lifetimeAccess && formValue.expirationDateYear
          ? new Date(formValue.expirationDateYear).getFullYear().toString()
          : undefined,
      expirationDateMonth: !formValue.lifetimeAccess
        ? formValue.expirationDateMonth
        : undefined,
    };

    onSave(newValue);
  }

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Add Licence and Certification?</div>
        <div className="form-desc">
          Enter your Licence and certification details here
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="licenseCertificates"
        onFinish={(values) => handleNewCertificationData(values)}
      >
        <Form.Item
          label="Name of Certification or Licence"
          required
          name="certificationName"
          rules={licenceCeritificationConfig.certificationName.rules}
        >
          <Input
            size="large"
            placeholder="Ex. Senior Management Certification"
          />
        </Form.Item>
        <Form.Item
          label="Issuing Organization"
          name="issuedBy"
          rules={[{ required: true }]}
        >
          <Input size="large" placeholder="Enter Organization Name" />
        </Form.Item>
        <FormItemLabel
          prefixCls="multiple-colon-form-item"
          label="Issue Date"
        />
        <div className="multiple-colon-form-item">
          <Form.Item name="issueDateMonth" rules={[{ required: true }]}>
            <Select
              options={monthSelectOptions}
              size="large"
              showSearch
              placeholder="Select month"
              notFoundContent="No Match Found"
            />
          </Form.Item>
          <Form.Item name="issueDateYear" rules={[{ required: true }]}>
            <DatePicker picker="year" size="large" />
          </Form.Item>
        </div>
        {lifetimeAccess !== true ? (
          <>
            <FormItemLabel
              prefixCls="multiple-colon-form-item"
              label="Expiration Date"
            />
            <div className="multiple-colon-form-item">
              <Form.Item name="expirationDateMonth">
                <Select
                  options={monthSelectOptions}
                  size="large"
                  showSearch
                  placeholder="Select month"
                  notFoundContent="No Match Found"
                />
              </Form.Item>
              <Form.Item name="expirationDateYear">
                <DatePicker picker="year" size="large" />
              </Form.Item>
            </div>
          </>
        ) : null}
        <Form.Item name="lifetimeAccess" valuePropName="checked">
          <Checkbox
            value={lifetimeAccess}
            onChange={() => setLifetimeAccess((prev) => !prev)}
          >
            Lifetime Access
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Creditional ID"
          name="credentialId"
          rules={[{ required: true }]}
        >
          <Input size="large" placeholder="Enter your Creditional ID here" />
        </Form.Item>
        <Form.Item label="Creditional URL (Optional)" name="credentialUrl">
          <Input size="large" placeholder="Enter your Creditional URL here" />
        </Form.Item>
        <Form.Item label="Relative Notes" name="relativeNotes">
          <TextArea rows={4} />
        </Form.Item>
        <div className="extra-info-text">
          Associate at least I skill to this licence or certification. It`ll
          also appear in your Skills section.
        </div>
        <Form.Item label="Skills" name="skills">
          <Button type="primary" ghost>
            <PlusOutlined /> Add Skills
          </Button>
        </Form.Item>
        <Form.Item>
          <Button className="app-btn-primary" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
