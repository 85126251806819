const workLocation = [
  {
    label: "Remote",
    value: "remote",
  },
  {
    label: "Hybrid",
    value: "hybrid",
  },
  {
    label: "In Office",
    value: "in-office",
  },
];

export default workLocation;
