import '../auto-apply-form.scss';
import { Button, Form, Select, Switch } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

interface AutoApplyStepTwoProps {
  formSubmit: (formData: AutoApplyStepTwoFormData) => void;
}

interface AutoApplyStepTwoFormData {
  [key: string]: string;
}

export default function AutoApplyStepTwo(props: AutoApplyStepTwoProps) {

  function handleFormSubmit(formData: AutoApplyStepTwoFormData) {
    props.formSubmit(formData);
  }

  return (
    <>
      <div className="form-header">
        <h3>Choose one or more level</h3>
        <Button type='primary' size='large' ghost>
          Reset
        </Button>
      </div>
      <div className="form">
        <Form
          onFinish={(values) => handleFormSubmit(values)}
          layout='vertical'>
          <div className='multiple-colon-form-item' style={{ marginTop: 20 }}>
            <Form.Item name="expLevel" label="Experience Level">
              <Select options={[]} size='large' showSearch placeholder='Select Experience Level' />
            </Form.Item>
            <Form.Item name="jobType" label="Job Type">
              <Select options={[]} size='large' showSearch placeholder='Select Job Type' />
            </Form.Item>
          </div>
          <div className='multiple-colon-form-item'>
            <Form.Item name="datePosted" label="Date Posted">
              <Select options={[]} size='large' showSearch placeholder='Select Post Date' />
            </Form.Item>
            <Form.Item name="industry" label="Industry">
              <Select options={[]} size='large' showSearch placeholder='Select Industry' />
            </Form.Item>
          </div>
          <div className='multiple-colon-form-item'>
            <Form.Item name="jobFunction" label="Job Function">
              <Select options={[]} size='large' showSearch placeholder='Select Job Function' />
            </Form.Item>
            <Form.Item name="companies" label="Companies">
              <Select options={[]} size='large' showSearch placeholder='Select Companies' />
            </Form.Item>
          </div>
          <div className='multiple-colon-form-item'>
            <Form.Item name="jobTitle" label="Job Title">
              <Select options={[]} size='large' showSearch placeholder='Select Job Title' />
            </Form.Item>
            <Form.Item name="workplace" label="Job Workplace">
              <Select options={[]} size='large' showSearch placeholder='Select Workplace' />
            </Form.Item>
          </div>
          <div className='multiple-colon-form-item'>
            <Form.Item name="salary" label="Job Salary">
              <Select options={[]} size='large' showSearch placeholder='Select Salary' />
            </Form.Item>
            <Form.Item name="benefits" label="Job Benefits">
              <Select options={[]} size='large' showSearch placeholder='Select Benefits' />
            </Form.Item>
          </div>
          <Button type='primary' size='large' htmlType='submit' ghost>
            Start Applying
            <ArrowRightOutlined />
          </Button>
        </Form>
      </div>
    </>
  )
}
