import { Menu, MenuProps } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import APP_MENU_ITEMS from "./menu-items";

export default function SideBar() {
  const [selectedItem, setSelectedItem] = useState('profiles');
  const navigate = useNavigate();
  const items: MenuProps['items'] = APP_MENU_ITEMS;

  const onClick: MenuProps['onClick'] = (e) => {
    setSelectedItem(e.key);
    navigate(e.key);
  };

  return <Menu onClick={onClick} selectedKeys={[selectedItem]} mode="inline" items={items} style={{ width: 331, padding: '12px' }} />;
}
