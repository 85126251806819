import { Button } from "antd";
import "./auto-apply-card.scss";

export interface AutoApplyCardProps {
  formKey: string;
  logo: string;
  buttonText: string;
  logoExpanded?: string;
  bullets?: string[];
  cardButtonClick: (key: string) => void;
  disabledAutoApply: boolean;
}

export default function AutoApplyCard(props: AutoApplyCardProps) {
  function handleButtonClick() {
    props.cardButtonClick(props.formKey);
  }
console.log(props.disabledAutoApply)

  return (
    <section className='auto-apply-card'>
      <div className='section-header'>
        <div className='logo'>
          <img width='100%' src={props.logo} />
        </div>
        <div className='logo-expanded'>
          <img width='100%' className='' src={props.logoExpanded} />
        </div>
      </div>
      {props.bullets?.length ? (
        <div className='bullet-list'>
          <ul>
            {props.bullets.map((bullet, i) => (
              <li key={i}>{bullet}</li>
            ))}
          </ul>
        </div>
      ) : null}
      <div className='card-button'>
        <Button type='primary' disabled={props.disabledAutoApply} size='large' block={false} onClick={() => handleButtonClick()}>
          {props.buttonText}
        </Button>
      </div>
    </section>
  );
}
