import { Button, Form, Input, Select } from "antd";
import "./register-wizard-forms-customization.scss";
import { Country, State, City } from "country-state-city";
import { useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { useDispatch, useSelector } from "../../../store";
import { UserLocationFormData } from "../../../interfaces";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";

export default function LocationForm() {
  const dispatch = useDispatch();
  const { userLocation } = useSelector(selectOnboardingSlice);
  const [stateOptions, setStateOptions] = useState<
    { key: string; value: string; code: string }[]
  >(
    State.getStatesOfCountry(
      Country.getAllCountries()?.find(
        (item) => item.name === userLocation?.country
      )?.isoCode
    )?.map((state) => ({
      key: state.name,
      value: state.name,
      code: state.isoCode,
    })) || []
  );

  const [cityOptions, setCityOptions] = useState<
    { key: string; value: string }[]
  >(
    City.getCitiesOfState(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      Country.getAllCountries().find(
        (item) => item.name === userLocation?.country
      )?.isoCode!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      stateOptions?.find((item) => item.key === userLocation?.state)?.code!
    ).map((city) => ({
      key: city.name,
      value: city.name,
    })) || []
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(
    Country.getAllCountries()?.find(
      (item) => item.name === userLocation?.country
    )?.isoCode || ""
  );

  const countryOptions = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.name,
    code: country.isoCode,
  }));

  function handleCountrySelect(selectedOption: DefaultOptionType) {
    setSelectedCountryCode(selectedOption.code);
    const states = State.getStatesOfCountry(selectedOption.code).map(
      (state) => ({
        key: state.name,
        value: state.name,
        code: state.isoCode,
      })
    );
    setStateOptions(states);
  }

  function handleStateSelect(selectedOption: DefaultOptionType) {
    const cities = City.getCitiesOfState(
      selectedCountryCode,
      selectedOption.code
    )?.map((city) => ({
      key: city.name,
      value: city.name,
    }));
    setCityOptions(cities);
  }

  function handleFormSubmit(values: UserLocationFormData) {
    dispatch(updateOnboarding({ userLocation: values, step: 2 }));
  }

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Where are you located?</div>
        <div className="form-desc">Enter your Address here</div>
      </div>
      <Form
        initialValues={{
          country: Country.getAllCountries()?.find(
            (item) => item.name === userLocation?.country
          )?.name,
          state: stateOptions?.find((item) => item.key === userLocation?.state)
            ?.value,
          city: cityOptions?.find((item) => item.key === userLocation?.city)
            ?.value,
          zipCode: userLocation?.zipCode,
          address: userLocation?.address,
        }}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <div className="multiple-colon-form-item">
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true }]}
          >
            <Select
              options={countryOptions}
              size="large"
              showSearch
              placeholder="Select Country"
              notFoundContent="No Match Found"
              onSelect={(_value, option) => handleCountrySelect(option)}
            />
          </Form.Item>
          <Form.Item label="State" name="state" rules={[{ required: true }]}>
            <Select
              options={stateOptions}
              size="large"
              showSearch
              placeholder="Select State"
              notFoundContent="No Match Found"
              onSelect={(_value, option) => handleStateSelect(option)}
            />
          </Form.Item>
        </div>
        <div className="multiple-colon-form-item">
          <Form.Item label="City" name="city" rules={[{ required: true }]}>
            <Select
              options={cityOptions}
              showSearch
              size="large"
              placeholder="Select City"
              notFoundContent="No Match Found"
            />
          </Form.Item>
          <Form.Item
            label="Zipcode"
            name="zipCode"
            rules={[{ required: true }]}
          >
            <Input size="large" placeholder="Enter Your Zip Code" autoComplete="off" />
          </Form.Item>
        </div>
        <Form.Item
          label="Street address"
          name="address"
          rules={[{ required: true }]}
        >
          <Input size="large" placeholder="Street Address here" />
        </Form.Item>
        <Form.Item>
          <Button className="app-btn-primary" type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
