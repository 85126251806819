import { useSelector } from "react-redux";
import { useGetSubscriptionDetailQuery, useGetUserQuery, useLazyCreateCheckoutSessionQuery } from "../../store/apis";
import { selectAuthSlice, selectRolesSlice } from "../../store/slices";
import { useEffect, useState } from "react";
import { Button, Spin, notification } from "antd";
import { useCancelSubscriptionMutation } from "../../store/apis";
import { APP_ENV } from "../../shared/constants";

export default function ManageSubscription() {
  const { data, isLoading, refetch } = useGetSubscriptionDetailQuery();
  const roles = useSelector(selectRolesSlice);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<string | undefined>();
  const [currentPeriodStart, setCurrentPeriodStart] = useState<string | undefined>();
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const { refetch: refetchUser } = useGetUserQuery();
  const [createCheckoutSession] = useLazyCreateCheckoutSessionQuery();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription().unwrap();
      notification.success({
        message: 'Subscription Cancelled',
        description: 'Your subscription has been cancelled successfully.',
      });
      refetch();
      refetchUser();
    } catch (error) {
      notification.error({
        message: 'Cancellation Failed',
        description: 'There was an error cancelling your subscription. Please try again.',
      });
    }
  };

  useEffect(() => {
    if (data?.current_period_end) {
      const date = new Date(data.current_period_end * 1000); // Adjust if your timestamp is in milliseconds
      const readableDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      setCurrentPeriodEnd(readableDate);
    }
    if (data?.start_date) {
      const date = new Date(data.start_date * 1000); // Adjust if your timestamp is in milliseconds
      const readableDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      setCurrentPeriodStart(readableDate);
    }
  }, [data]);

  const handleResubscribe = async () => {
    try {
      const response = await createCheckoutSession().unwrap();
      window.location.href = response.redirectUrl; // Navigate to the URL returned by the API
    } catch (error) {
      notification.error({
        message: 'Checkout Failed',
        description: 'There was an error creating the checkout session. Please try again.',
      });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="flex flex-col items-center p-6 bg-gray-50 min-h-screen">
        <div className="flex justify-center">
          <h2 className="text-xl font-bold mb-4">Manage Subscription</h2>
        </div>
        <ul className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
          <li className="mb-4">
            <span className="font-semibold">Current Plan:</span>
            <span className="ml-2">{roles[0].name}</span>
          </li>
          <li className="mb-4">
            <span className="font-semibold">Next Renewal Date:</span>
            {(data?.cancel_at_period_end != null && !data.cancel_at_period_end) && <span className="ml-2">{currentPeriodEnd}</span>}
            {data?.cancel_at_period_end && <span className="ml-2">We will not renew your subscription, and the plan you are using will end at the conclusion of this billing cycle.</span>}

          </li>
          <li className="mb-4">
            <span className="font-semibold">Start Date:</span>
            <span className="ml-2">{currentPeriodStart}</span>
          </li>
          {(data?.cancel_at_period_end != null && !data.cancel_at_period_end) &&
            <Button type="primary" danger className="mt-4" onClick={handleCancelSubscription}>
              Cancel Subscription
            </Button>
          }
          {data?.cancel_at_period_end && <Button type="primary" className="mt-4" onClick={handleResubscribe}>
            Resubscribe
          </Button>}
        </ul>
      </div>
    </Spin>
  );
}

