import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Form, Spin } from "antd";
import { Profile } from "../interfaces";
import { useCreateProfileMutation, useUpdateProfileMutation } from "../store/apis";
import { NotificationHelper } from "../helpers";
import { mapError } from "../shared/utils";

type EditProfileFormContextType = {
  profile?: Profile;
  updateProfileData: (data: Partial<Profile>) => void;
};

const defaultContextValues: EditProfileFormContextType = {
  updateProfileData: () => {},
};

export const EditProfileFormContext = createContext<EditProfileFormContextType>(defaultContextValues);

export const useEditProfileForm = () => {
  const context = useContext(EditProfileFormContext);

  if (!context) throw new Error("useEditProfileForm must be use inside VideoContextProvider");

  return context;
};

export interface EditProfileFormProviderProps extends PropsWithChildren {
  profile?: Profile;
  onEditSuccess?: () => void;
}

export const EditProfileFormProvider = ({ profile: originProfile, onEditSuccess = () => {}, children }: EditProfileFormProviderProps) => {
  const [profile, setProfile] = useState(originProfile);
  const [createProfile, createProfileRes] = useCreateProfileMutation();
  const [updateProfile, updateProfileRes] = useUpdateProfileMutation();

  const updateProfileData = useCallback((data: Partial<Profile>) => {
    setProfile((prev) => ({ ...prev, ...data }));
  }, []);

  const onSubmit = useCallback(
    async (data: any) => {
      const { personalDetails, links } = data;
      const action = profile?.id ? updateProfile : createProfile;

      const deletedWorkExperiences =
        originProfile?.workExperiences?.filter((item) => !profile?.workExperiences?.some((sItem) => sItem.id === item.id))?.map((item) => ({ ...item, delete: true })) || [];
      const deletedEducations =
        originProfile?.userEducations?.filter((item) => !profile?.userEducations?.some((sItem) => sItem.id === item.id))?.map((item) => ({ ...item, delete: true })) || [];
      const deletedLicenseCertificates =
        originProfile?.licenseCertifications
          ?.filter((item) => !profile?.licenseCertifications?.some((sItem) => sItem.id === item.id))
          ?.map((item) => ({ ...item, delete: true })) || [];

      const deletedQuestions =
        originProfile?.questions?.filter((item) => !profile?.questions?.some((sItem) => sItem.id === item.id))?.map((item) => ({ ...item, delete: true })) || [];

      await action({
        id: profile?.id as string,
        name: `${personalDetails?.firstName} ${personalDetails?.lastName}`,
        email: personalDetails?.mailId,
        gender: personalDetails?.gender,
        phone: `${personalDetails?.phone?.countryCode || ""}${personalDetails?.phone?.areaCode || ""}${personalDetails?.phone?.phoneNumber || ""}` || personalDetails?.phone || "",
        streetAddress: personalDetails?.address,
        city: personalDetails?.city,
        state: personalDetails?.state,
        country: personalDetails?.country,
        zipCode: personalDetails?.zipCode,
        coverLetter: profile?.coverLetter,
        relevantLinks: Object.keys(links)
          .map((key: any) => ({
            link: links[key] || "",
            name: key || "",
          }))
          ?.filter((item: any) => !!item?.link?.trim()),
        workExperiences: [...(profile?.workExperiences || []), ...deletedWorkExperiences],
        userEducations: [...(profile?.userEducations || []), ...deletedEducations],
        licenseCertifications: [...(profile?.licenseCertifications || []), ...deletedLicenseCertificates],
        questions: [...(profile?.questions || []), ...deletedQuestions],
      });
    },
    [profile]
  );

  useEffect(() => {
    setProfile(originProfile);
  }, [originProfile]);

  useEffect(() => {
    if (createProfileRes?.error || updateProfileRes?.error) {
      NotificationHelper.error(mapError(createProfileRes?.error || updateProfileRes?.error));
      return;
    }
    if (createProfileRes?.data && !createProfileRes?.error) {
      onEditSuccess();
    }
    if (updateProfileRes?.data && !updateProfileRes?.error) {
      onEditSuccess();
    }
  }, [createProfileRes, updateProfileRes, onEditSuccess]);

  const loading = useMemo(() => !!createProfileRes?.isLoading || !!updateProfileRes?.isLoading, [createProfileRes, updateProfileRes]);

  return (
    <Spin spinning={loading}>
      <EditProfileFormContext.Provider
        value={{
          profile,
          updateProfileData,
        }}
      >
        <Form.Provider
          onFormFinish={async (name, { forms }) => {
            try {
              if (name === "workExperience" || name === "education" || name === "licenseCertificates" || name === "questions") {
                return;
              }
              const { linksForm, personalInfoForm } = forms;
              await personalInfoForm.validateFields();
              const personalDetails = personalInfoForm.getFieldsValue();
              const links = linksForm.getFieldsValue();
              onSubmit({ personalDetails, links });
            } catch (_) {
              // show error toast?
            }
          }}
        >
          {children}
        </Form.Provider>
      </EditProfileFormContext.Provider>
    </Spin>
  );
};
