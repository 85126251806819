import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { EducationFormData, LicenceAndCertificationFormData, PersonalDetailsFormData, UserLocationFormData, WorkExperienceFormData } from "../../interfaces";

export interface OnboardingState {
  step: number;
  workExperiences?: WorkExperienceFormData[];
  links?: { link?: string, type: string }[];
  personalDetails?: PersonalDetailsFormData;
  userLocation?: UserLocationFormData;
  licenseCertifications?: LicenceAndCertificationFormData[];
  userEducations?: EducationFormData[];
}

const initialState: OnboardingState = {
  step: 0,
};

/**
 * Slice
 */
export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateOnboarding: (
      state,
      action: PayloadAction<Partial<OnboardingState>>
    ) => {
      return { ...state, ...action.payload };
    },
    resetOnboarding: () => {
      return initialState;
    },
  },
});

/**
 * Actions
 */
export const { updateOnboarding, resetOnboarding } = onboardingSlice.actions;

/**
 * Selectors
 */
export const selectOnboardingSlice = (state: AppState) => state.onboarding;
