import { Navigate, createBrowserRouter } from "react-router-dom";
import OnboardingPage from "./pages/onboarding/onboarding";
import Login from "./pages/login/login";
import PATH_DEFINITIONS from "./shared/constants/path-definitions";
import UploadCVPage from "./pages/upload-cv/upload-cv";
import AutoApply from "./pages/auto-apply/auto-apply";
import AutoApplyForm from "./pages/auto-apply-form/auto-apply-form";
import Dashboard from "./pages/dashboard/dashboard";
import Profiles from "./pages/profiles/profiles";
import EditProfile from "./pages/edit-profile/edit-profile";
import Subscription from "./pages/subscription/subscription";
import PaymentSuccess from "./pages/payment/PaymentSucess";
import PaymentFail from "./pages/payment/PaymentFail";
import HandlePayment from "./components/handle-payment/payment-form";
import ContactUs from "./pages/contact-us/contact-us";

export const appRouter = (initialPath: string) =>
  createBrowserRouter([
    {
      path: "/",
      children: [
        {
          index: true,
          element: <Navigate to={initialPath} replace />,
        },
        {
          path: PATH_DEFINITIONS.login,
          element: <Login />,
        },
        {
          path: PATH_DEFINITIONS.onboarding,
          element: <OnboardingPage />,
        },
        {
          path: PATH_DEFINITIONS.uploadCV,
          element: <UploadCVPage />,
        },

        {
          path: PATH_DEFINITIONS.paymentSuccess,
          element: <PaymentSuccess />,
        },
        {
          path: PATH_DEFINITIONS.paymentFail,
          element: <PaymentFail />,
        },
        {
          path: PATH_DEFINITIONS.dashboard,
          element: <Dashboard />,
          children: [
            {
              index: true,
              element: <AutoApply />,
            },
            {
              path: PATH_DEFINITIONS.dashboardProfiles,
              element: <Profiles />,
            },
            {
              path: PATH_DEFINITIONS.payment,
              element: <HandlePayment />,
            },
            {
              path: PATH_DEFINITIONS.dashboardEditProfile,
              element: <EditProfile />,
            },
            {
              path: `${PATH_DEFINITIONS.autoApplyForm}/:formKey`,
              element: <AutoApplyForm />,
            },
            {
              path: PATH_DEFINITIONS.subscription,
              element: <Subscription />,
            },
            {
              path: PATH_DEFINITIONS.contactUs,
              element: <ContactUs />,
            },
          ],
        },
      ],
    },
  ]);
