import "../auto-apply-form.scss";
import { Button, Form, Input, Select, Switch } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import JobFilters from "./job-filters";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSlice, selectAuthSlice, selectProfilesSlice } from "../../../store/slices";
import { siteName_URL_MAP } from "./siteName_URL_Map";
import { isProfileComplete } from "../../../shared/utils/profile.utils";
import { useNavigate } from "react-router-dom";

interface AutoApplyStepOneFormData {
  [key: string]: string;
}

export default function AutoApplyStepOne({ siteName }: { siteName: string }) {
  const [formData, setFormData] = useState<Record<string, string>>({});
  const { items } = useSelector(selectProfilesSlice);
  const { user } = useSelector(selectAuthSlice);
  const [missingExtensionText, setMissingExtensionText] = useState("Please install the extension first, then refresh the page");
  const navigate = useNavigate();
  const { token } = useSelector(selectAuthSlice);

  useEffect(() => {
    window.postMessage({ type: "extensionSet", data: { token } });
  }, []);

  useEffect(() => {
    function messageHandler(e: MessageEvent) {
      try {
        if (e.origin != location.origin) return;
        if (e.data.type == "extensionExists") {
          setMissingExtensionText("");
        }
      } catch (e) {
        console.log(e);
      }
    }
    window.addEventListener("message", messageHandler);
    setTimeout(() => {
      window.postMessage({ type: "extensionExists?" });
    }, 500);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  function handleFormSubmit(_formData: AutoApplyStepOneFormData) {
    setFormData(_formData);
    const { jobTitle, resume } = _formData;
    window.postMessage({ type: "extensionSet", data: { profileId: resume, userId: user?.id } });
    window.postMessage({ type: "fetchJobFilters", data: siteName_URL_MAP[siteName].fetchJobFilters + jobTitle }, "*");
    window.postMessage({ type: "extensionSet", data: { profileId: resume, userId: user?.id } });
  }

  function handleProfileSelection(_: string, option: { value: any; isComplete: boolean }) {
    if (!option?.isComplete && confirm("This profile is not complete. Do you want to continue?")) {
      navigate(`/dashboard/profile/${option?.value}`);
    }
  }

  return (
    <>
      <h3>
        Start Applying on <span className='capitalize'>{siteName}</span>
      </h3>
      <div className='section-text'>
        Make sure to login in the <span className='capitalize'>{siteName}</span> account of the person with the selected resume and his/her indeed profile shoutd be completed
        before applying.
      </div>
      <div className='form'>
        <Form onFinish={(values) => handleFormSubmit(values)} onChange={() => setFormData({})} layout='vertical'>
          <div className='multiple-colon-form-item' style={{ marginTop: 40 }}>
            <Form.Item name='savedSetting' label='Saved Search Setting (Optional)'>
              <Select options={[]} size='large' showSearch placeholder='Select saved setting' />
            </Form.Item>
            <div className='form-item-with-desc'>
              <Form.Item name='resume' label='Choose a Profile to apply jobs for' required>
                <Select
                  options={items.map((item) => ({
                    value: item.id,
                    label: item.profileName,
                    isComplete: isProfileComplete(item),
                  }))}
                  size='large'
                  showSearch
                  placeholder='Select a resume'
                  onSelect={handleProfileSelection}
                />
              </Form.Item>
              <div className='form-item-desc'>Select Resume to use while Applying on Indeed</div>
            </div>
          </div>
          <div className='multiple-colon-form-item'>
            <div className='form-item-with-desc'>
              <Form.Item name='jobTitle' label='Enter skill / job title' required>
                <Input size='large' placeholder='Ex. Senior Ecommerce Manager' required />
              </Form.Item>
              <div className='form-item-desc'>For example - Enter only one single skill or job title like react or data analyst or marketing manager etc</div>
            </div>
            <div className='form-item-with-desc'>
              <Form.Item name='jobCount' label='Enter number of Jobs to apply to' required>
                <Input size='large' type='number' min={1} placeholder='Ex. 4' required />
              </Form.Item>
              <div className='form-item-desc'>Enter How many jobs you want to automate - Min: 1</div>
            </div>
          </div>
          <div className='multiple-colon-form-item' style={{ marginBottom: 40 }}>
            <div className='form-item-with-desc'>
              <Form.Item name='location' label='Enter the job location (Optional)'>
                <Select options={[]} size='large' showSearch placeholder='Select a location' />
              </Form.Item>
              <div className='form-item-desc'>For example - you can enter your location like - United States or San Fransico, London, Bangalore or any other place</div>
            </div>
            <div className='switch-with-label'>
              <div className='switch-label'>Job Title Filters - Advance Search</div>
              <Switch />
            </div>
          </div>

          <div className='button-wrapper'>
            <Button disabled={!!missingExtensionText} type='primary' htmlType='submit' size='large' ghost block>
              {missingExtensionText ? (
                missingExtensionText
              ) : (
                <>
                  Click to fetch results first
                  <ArrowRightOutlined />
                </>
              )}
            </Button>
          </div>
        </Form>
      </div>
      <div className='p-4' key={JSON.stringify(formData)}>
        <JobFilters formData={formData} siteName={siteName} />
      </div>
    </>
  );
}
