import { Avatar, Flex, Form, Input, Select } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  BehanceOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Country, State, City } from "country-state-city";
import PhoneInput from "antd-phone-input";
import "./edit-profile.scss";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { useEditProfileForm } from "../../contexts";
import { populatePersonalInfoInitialFormValues } from "./edit-profile.utils";

export default function PersonalInformation() {
  const [form] = Form.useForm();
  const { profile } = useEditProfileForm();
  useEffect(() => {
    const initialValues = populatePersonalInfoInitialFormValues(profile);
    form.setFieldsValue(initialValues);
  }, [profile]);

  const staticFormItems = [
    { key: "instagram", icon: <InstagramOutlined style={{ fontSize: 24 }} /> },
    { key: "facebook", icon: <FacebookOutlined style={{ fontSize: 24 }} /> },
    { key: "linkedin", icon: <LinkedinOutlined style={{ fontSize: 24 }} /> },
    { key: "behance", icon: <BehanceOutlined style={{ fontSize: 24 }} /> },
  ];

  const genderList = [
    { key: "male", value: "Male" },
    { key: "female", value: "Female" },
    { key: "other", value: "Other" },
  ];

  const [stateOptions, setStateOptions] = useState<any>([]);
  const [cityOptions, setCityOptions] = useState<any>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("");
  const countryOptions = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.name,
    code: country.isoCode,
  }));

  function handleCountrySelect(selectedOption: DefaultOptionType) {
    setSelectedCountryCode(selectedOption.code);
    const states = State.getStatesOfCountry(selectedOption.code).map(
      (state) => ({
        key: state.name,
        value: state.name,
        code: state.isoCode,
      })
    );
    setStateOptions(states);
  }

  function handleStateSelect(selectedOption: DefaultOptionType) {
    const cities = City.getCitiesOfState(
      selectedCountryCode,
      selectedOption.code
    )?.map((city) => ({
      key: city.name,
      value: city.name,
    }));
    setCityOptions(cities);
  }

  return (
    <div className="edit-profile-wrapper">
      <div className="container personal-information-section">
        <div className="title">Personal Information</div>
        <Flex className="pi-grid" justify="space-between" gap={20}>
          <Flex vertical gap={40}>
            <Flex justify="center" align="center">
              <Avatar size={150} icon={<UserOutlined />} />
            </Flex>

            <Form name="linksForm" className="mt-7">
              {staticFormItems.map((item, index) => {
                return (
                  <Flex key={index} gap={8}>
                    <Flex
                      justify="center"
                      className="border flex-shrink-0 rounded-md w-10 h-10"
                    >
                      {item.icon}
                    </Flex>
                    <Form.Item
                      name={item.key}
                      initialValue={
                        profile?.relevantLinks?.find(
                          (oItem) => oItem.name === item.key
                        )?.link
                      }
                    >
                      <Input
                        size="large"
                        className="social-input"
                        placeholder={`Link URL`}
                      />
                    </Form.Item>
                  </Flex>
                );
              })}
            </Form>
          </Flex>
          <Form
            layout="vertical"
            name="personalInfoForm"
            className="flex-1"
            initialValues={{ phone: profile?.phone }}
            form={form}
          >
            <div className="multiple-colon-form-item">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Enter your first name here" autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Enter your last name here" autoComplete="off" />
              </Form.Item>
            </div>
            <div className="multiple-colon-form-item">
              <Form.Item
                label="Email ID"
                name="mailId"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Enter your Mail ID" autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[{ required: true }]}
              >
                <PhoneInput country="us" enableSearch />
              </Form.Item>
            </div>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select
                options={genderList}
                placeholder="Select Gender"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Street address"
              name="address"
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="Street Address here" autoComplete="off" />
            </Form.Item>

            <div className="multiple-colon-form-item">
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true }]}
              >
                <Select
                  options={countryOptions}
                  size="large"
                  showSearch
                  placeholder="Select Country"
                  notFoundContent={ <span>No Match Found</span> }
                  onSelect={(_value, option) => handleCountrySelect(option)}
                />
              </Form.Item>
              <Form.Item
                label="State"
                name="state"
                rules={[{ required: true }]}
              >
                <Select
                  options={stateOptions}
                  size="large"
                  showSearch
                  placeholder="Select State"
                  notFoundContent={ <span>No Match Found</span> }
                  onSelect={(_value, option) => handleStateSelect(option)}
                />
              </Form.Item>
              <Form.Item label="City" name="city" rules={[{ required: true }]}>
                <Select
                  options={cityOptions}
                  showSearch
                  size="large"
                  placeholder="Select City"
                  notFoundContent={ <span>No Match Found</span> }
                />
              </Form.Item>
            </div>
          </Form>
        </Flex>
      </div>
    </div>
  );
}
