import { Button, Flex } from "antd";
import "./edit-profile.scss";
import ReviewCard from "../review-card/review-card";
import { useMemo, useRef, useState } from "react";
import { EducationFormData } from "../../interfaces";
import { useEditProfileForm } from "../../contexts";
import { EducationFormModal } from "../modals";

export default function EducationDetail() {
  const { profile, updateProfileData } = useEditProfileForm();
  const data = useMemo(
    () => profile?.userEducations || [],
    [profile?.userEducations]
  );

  const [open, setOpen] = useState(false);
  const [editThisData, setEditThisData] = useState<EducationFormData>();
  const itemIndex = useRef(0);

  const handleCancel = () => {
    setOpen(false);
    setEditThisData(undefined);
  };

  const showAddModal = () => {
    setEditThisData(undefined);
    setOpen(true);
  };

  const showEditModal = (data: EducationFormData) => {
    setEditThisData(data);
    setOpen(true);
  };

  const onSubmit = (values: EducationFormData) => {
    if (!profile?.userEducations?.length) {
      updateProfileData({ userEducations: [values] });
      handleCancel();
      return;
    }

    if (editThisData) {
      // udpate existing
      updateProfileData({
        userEducations: profile?.userEducations?.map((oItem, index) =>
          index === itemIndex.current ? values : oItem
        ),
      });
    } else {
      // add new one
      updateProfileData({
        userEducations: [...(profile?.userEducations || []), values],
      });
    }
    handleCancel();
  };

  const onDelete = (key: number) => {
    const newItems = profile?.userEducations?.filter(
      (_, index) => index !== key
    );
    updateProfileData({ userEducations: newItems });
  };

  return (
    <div className="edit-profile-wrapper">
      <div className="container">
        <Flex justify="space-between" align="center">
          <div className="title">Education</div>
          <Button
            className="app-btn-primary"
            type="primary"
            onClick={showAddModal}
          >
            Add Education
          </Button>
        </Flex>
        <div style={{ marginTop: "18px" }}>
          {data?.map((item, index) => (
            <div key={index} className="space-vertical">
              <ReviewCard
                items={[
                  {
                    key: index,
                    title: item.fieldOfStudy,
                    subtitles: [
                      item.school,
                      item.levelOfEducation,
                      `${item.monthFrom}/${item.yearFrom} - ${
                        item.currentlyStudying
                          ? "Present"
                          : `${item.monthTo || ""}${item.monthTo ? "/" : ""}` +
                            `${item.yearTo || ""}`
                      }`,
                    ],
                  },
                ]}
                onDelete={onDelete}
                onEdit={() => {
                  showEditModal(item);
                  itemIndex.current = index;
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <EducationFormModal
        open={open}
        width={"60vw"}
        destroyOnClose
        onCancel={handleCancel}
        footer={null}
        onSave={onSubmit}
        formData={editThisData}
      />
    </div>
  );
}
