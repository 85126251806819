import { Flex, Layout } from "antd";
import { Navigate, Outlet } from "react-router-dom";

import "./dashboard.scss";
import NavBar from "../../components/navbar/navbar";
import SideBar from "../../components/sidebar/sidebar";
import { useSelector } from "../../store";
import { selectAuthSlice } from "../../store/slices";

export default function Dashboard() {
  const { user, initialPath } = useSelector(selectAuthSlice);

  if (!user) {
    return <Navigate to={initialPath} replace />
  }

  return (
    <Layout>
      <NavBar />
      <Flex className="dashboard">
        <SideBar />
        <Layout className="outlet-section">
          <Outlet />
        </Layout>
      </Flex>
    </Layout>
  );
}
