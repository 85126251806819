import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, Form, Input, InputNumber } from "antd";
import { QUESTION_TYPES } from "../../../shared/constants/question.types";
import FormItemLabel from "antd/es/form/FormItemLabel";
import { PlusCircleFilled, DeleteFilled } from "@ant-design/icons";

const { TextArea } = Input;

interface Props {
  questionType: QUESTION_TYPES;
  answers: string | string[];
  updateAnswers?: (answers: string[]) => void;
}

export const QuestionAnwers = ({
  questionType = QUESTION_TYPES.text,
  answers = [],
  updateAnswers = () => {},
}: Props) => {
  const renderAnswers = useMemo(() => {
    switch (questionType) {
      case QUESTION_TYPES.text:
        return (
          <Form.Item name="answers" label="Answer" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
        );
      case QUESTION_TYPES.number:
        return (
          <Form.Item
            name="answers"
            label="Answers"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} className="w-1/2 mt-1" size="large" />
          </Form.Item>
        );
      case QUESTION_TYPES.single_choice:
      case QUESTION_TYPES.multi_choice:
        return (
          <MultipleAnswers
            answers={answers as string[]}
            onChangeValues={(values) => updateAnswers(values)}
          />
        );
      default:
        break;
    }
    return null;
  }, [questionType, answers, updateAnswers]);

  return renderAnswers;
};

interface MultipleAnswersProps {
  answers?: string[];
  onChangeValues?: (values: string[]) => void;
}

const MultipleAnswers = ({
  answers = [],
  onChangeValues = () => {},
}: MultipleAnswersProps) => {
  const [options, setOptions] = useState(
    Array.isArray(answers || []) && answers?.length
      ? answers
      : Array.from(Array(4), () => "")
  );

  useEffect(() => {
    setOptions(
      Array.isArray(answers || []) && answers?.length
        ? answers
        : Array.from(Array(4), () => "")
    );
  }, [answers]);

  return (
    <>
      <Flex className="items-center">
        <FormItemLabel prefixCls="" label="Answers" required />
        <Button
          type="link"
          className="flex flex-row items-center"
          onClick={() => {
            setOptions([...options, ""]);
          }}
        >
          <PlusCircleFilled />
        </Button>
      </Flex>
      <Flex vertical>
        <Form.Item name="answers" hidden>
          <Input />
        </Form.Item>
        {options.map((item, index) => (
          <Flex key={index} className="mt-2">
            <Input
              value={item}
              size="large"
              onChange={(e) => {
                setOptions((prev) =>
                  prev.map((item, sIndex) =>
                    sIndex === index ? e.target.value : item
                  )
                );
                onChangeValues(
                  options.map((item, sIndex) =>
                    sIndex === index ? e.target.value : item
                  )
                );
              }}
            />
            <Button
              type="link"
              onClick={() => {
                if (options?.length < 3) {
                  return;
                }
                setOptions(options.filter((item, sIndex) => sIndex !== index));
                onChangeValues(
                  options.filter((item, sIndex) => sIndex !== index)
                );
              }}
            >
              <DeleteFilled />
            </Button>
          </Flex>
        ))}
      </Flex>
    </>
  );
};
