import { Modal, ModalProps } from 'antd'
import React from 'react'
import { LicenceAndCertificationFormData } from '../../interfaces'
import LicenceAndCertificationForm from '../forms/onboarding/licence-certification-form';

interface Props extends ModalProps {
  formData?: LicenceAndCertificationFormData;
  onSave?: (values: LicenceAndCertificationFormData) => void;
}

export const LicenseCertificatesFormModal = ({ formData, onSave = () => { }, ...rest }: Props) => {
  return (
    <Modal
      width={'60vw'}
      footer={null}
      {...rest}
    >
      <LicenceAndCertificationForm formData={formData} onSave={onSave} />
    </Modal>
  )
}
