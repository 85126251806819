import { Button, Form, Input, Select } from "antd";
import { useEffect } from "react";

import "./register-wizard-forms-customization.scss";

import FormItemLabel from "antd/es/form/FormItemLabel";
import { QuestionFormData } from "../../../interfaces";
import { QUESTION_TYPES, questionOptions } from "../../../shared/constants/question.types";
import { QuestionAnwers } from "./question-answers";

const defaultFormValues: QuestionFormData = {
  id: "",
  questionText: "",
  questionType: QUESTION_TYPES.text,
  answers: [],
  stage: "",
  host: "",
};

export interface QuestionFormProps {
  formData?: QuestionFormData;
  onSave?: (value: QuestionFormData) => void;
}

const convertQuestionType = (type: string) => {
  switch (type.toLowerCase()) {
    case "tel":
      return "text";
    default:
      return type;
  }
};

export default function QuestionForm({ formData, onSave = () => {} }: QuestionFormProps) {
  const [form] = Form.useForm<QuestionFormData>();
  const watch = Form.useWatch([], form);

  useEffect(() => {
    if (formData) {
      const mappedFormData = { ...formData, questionType: convertQuestionType(formData.questionType) };
      form.setFieldsValue(mappedFormData);
    } else {
      form.setFieldsValue(defaultFormValues);
    }
  }, [formData, form]);

  function handleNewQuestionData(formValue: QuestionFormData) {
    const newValue = {
      ...formValue,
      id: formData?.id,
    };
    onSave(newValue);
  }

  return (
    <div className='form-wrapper'>
      <div className='form-header'>
        <div className='form-title'>Add Question</div>
        <div className='form-desc'>Enter your questions and answers here</div>
      </div>
      <Form form={form} layout='vertical' name='questions' onFinish={(values) => handleNewQuestionData(values)}>
        <Form.Item label='Question' required name='questionText' rules={[{ required: true }]}>
          <Input size='large' placeholder='Ex. Why do you think you are suitable for this role?' />
        </Form.Item>

        <FormItemLabel prefixCls='multiple-colon-form-item' label='Question Type' />
        <Form.Item name='questionType' rules={[{ required: true }]}>
          <Select options={questionOptions} size='large' placeholder='Select question type' onChange={() => form.setFieldValue("answers", "")} />
        </Form.Item>
        <QuestionAnwers
          questionType={watch?.questionType as QUESTION_TYPES}
          answers={watch?.answers as string[]}
          updateAnswers={(answers) => {
            form.setFieldValue("answers", answers);
          }}
        />
        <Form.Item label='Stage' name='stage' className='mt-4'>
          <Input size='large' />
        </Form.Item>
        <Form.Item label='Host' name='host'>
          <Input size='large' />
        </Form.Item>
        <Form.Item>
          <Button className='app-btn-primary mt-4' type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
