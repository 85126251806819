import { Button } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import "./review-card.scss";

export interface ReviewCardProps {
  items: ReviewCardItem[];
  onEdit?: (key: number) => void;
  onDelete?: (key: number) => void;
}

export interface ReviewCardItem {
  key: number;
  title: string;
  subtitles: string[];
}

export default function ReviewCard(props: ReviewCardProps) {
  function onEditButtonClick(key: number) {
    if (props.onEdit) {
      props.onEdit(key);
    }
  }

  function onDeleteButtonClick(key: number) {
    if (props.onDelete) {
      props.onDelete(key);
    }
  }

  function renderReviewItem() {
    return props.items.map((item, index) => (
      <div key={index} className="review-item">
        <div className="review-card-header">
          <h4>{item.title}</h4>
          <div className="review-card-buttons">
            <Button type="link" onClick={() => onEditButtonClick(item.key)}>
              <EditFilled />
            </Button>
            <Button type="link" onClick={() => onDeleteButtonClick(item.key)}>
              <DeleteFilled />
            </Button>
          </div>
        </div>
        {renderSubtitles(item.subtitles)}
      </div>
    ));
  }

  function renderSubtitles(subtitles?: string[]) {
    if (subtitles?.length) {
      return subtitles.map((subtitle, index) => <p key={index}>{subtitle}</p>);
    } else {
      return null;
    }
  }

  if (!props?.items?.length) {
    return null
  }

  return (
    <section className="review-card-wrapper">{renderReviewItem()}</section>
  );
}
