import Dragger from 'antd/es/upload/Dragger';
import './file-upload.scss';

export default function FileUpload() {
  return (
    <Dragger>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  )
}
