import "./review-onboarding.scss";
import { Avatar, Button, Divider, Input } from "antd";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import ReviewCard from "../../../components/review-card/review-card";
import { useDispatch, useSelector } from "../../../store";
import { resetOnboarding, selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import {
  EducationFormData,
  LicenceAndCertificationFormData,
  WorkExperienceFormData,
} from "../../../interfaces";
import { useOnboardingMutation } from "../../../store/apis";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber, mapError } from "../../../shared/utils";
import { EducationFormModal, LicenseCertificatesFormModal, WorkExperienceFormModal } from "../../../components/modals";

enum ModalType {
  EDUCATION = "education",
  WORK_EXPERIENCE = "work_experience",
  LICENSE = "license"
}

type ModalDataTypes = EducationFormData | WorkExperienceFormData | LicenceAndCertificationFormData;

export default function ReviewOnboarding() {
  const dispatch = useDispatch();
  const {
    personalDetails,
    workExperiences = [],
    userEducations = [],
    userLocation,
    licenseCertifications = [],
    links = [],
  } = useSelector(selectOnboardingSlice);
  const navigate = useNavigate();
  const [submitOnboarding, { data, isLoading, error }] =
    useOnboardingMutation();
  const [visibleModal, setVisibleModal] = useState<{ type: ModalType; data: ModalDataTypes, dataIndex: number }>();

  const onSubmit = async () => {
    await submitOnboarding({
      name: `${personalDetails?.firstName} ${personalDetails?.lastName}`,
      email: personalDetails?.mailId!,
      phone: `${personalDetails?.phone?.countryCode}${personalDetails?.phone?.areaCode}${personalDetails?.phone?.phoneNumber}`,
      gender: personalDetails?.gender!,
      streetAddress: userLocation?.address!,
      city: userLocation?.city!,
      state: userLocation?.state!,
      country: userLocation?.country!,
      zipCode: userLocation?.zipCode!,
      relevantLinks: links
        ?.map((item) => ({
          link: item.link || "",
          name: item.type || "",
        }))
        ?.filter((item) => !!item?.link?.trim()),
      licenseCertifications,
      workExperiences,
      userEducations,
    });
  };

  useEffect(() => {
    if (data && !error) {
      navigate("/dashboard");
      dispatch(resetOnboarding());
    }
  }, [data, error, navigate]);

  return (
    <div className="onboarding-review-wrapper">
      <div className="header">
        <Avatar>
          {personalDetails?.firstName?.toUpperCase()?.[0] || ""}
          {personalDetails?.lastName?.toUpperCase()?.[0] || ""}
        </Avatar>
        <span className="section-title">
          {personalDetails?.firstName || ""} {personalDetails?.lastName || ""}
        </span>
      </div>
      <div className="section-field">
        <div className="section-subtitle">Personal Details</div>
        <div className="icon-text">
          <PhoneOutlined />
          <span className="section-text">
            {formatPhoneNumber(
              `${personalDetails?.phone.countryCode}${personalDetails?.phone?.areaCode}${personalDetails?.phone?.phoneNumber}`
            )}
          </span>
        </div>
        <div className="icon-text">
          <EnvironmentOutlined />
          <span className="section-text">{`${userLocation?.city || ""} ${userLocation?.country || ""
            }`}</span>
        </div>
      </div>
      <Divider style={{ border: "1px solid #eee" }} />
      <div className="section-field">
        <div className="section-subtitle">Qualification</div>
        <ReviewCard
          items={userEducations.map((value: EducationFormData, i: number) => ({
            key: i,
            title: value.fieldOfStudy,
            subtitles: [
              value.school,
              value.levelOfEducation,
              `${value.monthFrom}/${value.yearFrom} - ${value.currentlyStudying
                ? "Present"
                : `${value.monthTo || ""}${value.monthTo ? "/" : ""}` +
                `${value.yearTo || ""}`
              }`,
            ],
          }))}
          onDelete={(key) =>
            dispatch(
              updateOnboarding({
                userEducations: userEducations?.filter(
                  (_, index) => index !== key
                ),
              })
            )
          }
          onEdit={(key) => setVisibleModal({ type: ModalType.EDUCATION, data: userEducations?.[key], dataIndex: key })}
        />
      </div>
      <div className="section-field">
        <div className="section-subtitle">Work Experience</div>
        <ReviewCard
          items={workExperiences?.map(
            (value: WorkExperienceFormData, i: number) => ({
              key: i,
              title: value.jobTitle,
              subtitles: [
                value.companyName,
                `${value.startMonth}/${value.startYear} - ${value.currentlyWorking
                  ? "Present"
                  : `${value.endMonth || ""}${value.endMonth ? "/" : ""}` +
                  `${value.endYear || ""}`
                }`,
              ],
            })
          )}
          onDelete={(key) =>
            dispatch(
              updateOnboarding({
                workExperiences: workExperiences?.filter(
                  (_, index) => index !== key
                ),
              })
            )
          }
          onEdit={(key) => setVisibleModal({ type: ModalType.WORK_EXPERIENCE, data: workExperiences?.[key], dataIndex: key })}
        />
      </div>
      <div className="section-field">
        <div className="section-subtitle">Certification and License</div>
        <ReviewCard
          items={licenseCertifications.map(
            (value: LicenceAndCertificationFormData, i: number) => ({
              key: i,
              title: value.certificationName,
              subtitles: [
                value.issuedBy,
                `${value.issueDateMonth}/${value.issueDateYear} - ${value.lifetimeAccess
                  ? "Lifetime Access"
                  : `${value.expirationDateMonth || ""}${value.expirationDateMonth ? "/" : ""
                  }` + `${value.expirationDateYear || ""}`
                }`,
              ],
            })
          )}
          onDelete={(key) =>
            dispatch(
              updateOnboarding({
                licenseCertifications: licenseCertifications?.filter(
                  (_, index) => index !== key
                ),
              })
            )
          }
          onEdit={(key) => setVisibleModal({ type: ModalType.LICENSE, data: licenseCertifications?.[key], dataIndex: key })}
        />
      </div>
      <div className="section-field">
        <div className="section-subtitle">Relevant Links</div>
        {links
          ?.filter((item) => !!item?.link)
          ?.map((link, index) => (
            <Input
              key={index}
              disabled
              value={link?.link}
              size="large"
              placeholder="link URL"
            />
          ))}
      </div>
      {error && mapError(error)?.message ? (
        <div className="error-message">{mapError(error)?.message}</div>
      ) : null}
      <div className="submit-btn">
        <Button
          type="primary"
          size="large"
          block
          onClick={onSubmit}
          loading={isLoading}
        >
          Save & Continue
        </Button>
      </div>
      <EducationFormModal
        open={visibleModal?.type === ModalType.EDUCATION}
        formData={visibleModal?.data as EducationFormData}
        onCancel={() => setVisibleModal(undefined)}
        onSave={(values) => {
          dispatch(
            updateOnboarding({
              userEducations: userEducations?.map((education, index) =>
                visibleModal?.dataIndex === index ? values : education
              )
            })
          );
          setVisibleModal(undefined);
        }}
      />
      <WorkExperienceFormModal
        open={visibleModal?.type === ModalType.WORK_EXPERIENCE}
        formData={visibleModal?.data as WorkExperienceFormData}
        onCancel={() => setVisibleModal(undefined)}
        onSave={(values) => {
          dispatch(
            updateOnboarding({
              workExperiences: workExperiences?.map((education, index) =>
                visibleModal?.dataIndex === index ? values : education
              )
            })
          );
          setVisibleModal(undefined);
        }}
      />
      <LicenseCertificatesFormModal
        open={visibleModal?.type === ModalType.LICENSE}
        formData={visibleModal?.data as LicenceAndCertificationFormData}
        onCancel={() => setVisibleModal(undefined)}
        onSave={(values) => {
          dispatch(
            updateOnboarding({
              licenseCertifications: licenseCertifications?.map((education, index) =>
                visibleModal?.dataIndex === index ? values : education
              )
            })
          );
          setVisibleModal(undefined);
        }}
      />
    </div>
  );
}
