import { ReactNode } from "react";
import './promo-card.scss';

interface PromoCardProps {
  header: ReactNode;
  icon: ReactNode;
  text: string;
  subtext?: string;
  cardBackgroundColor: string;
  roleId?: string;
}

export default function PromoCard(props: PromoCardProps) {

  return (
    <section style={{ backgroundColor: props.cardBackgroundColor }} className="promo-card">
      <div className="promo-card-header">
        {props.header}
      </div>
      <div className="promo-card-body">
        <div className="promo-card-icon">
          {props.icon}
        </div>
        <div className="promo-card-texts">
          <div className="section-title">
            {props.text}
          </div>
          <div className="section-text">
            {props.subtext}
          </div>
        </div>
      </div>
    </section>
  )
}
