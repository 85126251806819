import { CacheLocation } from "@auth0/auth0-react";
import { APP_ENV } from "./env.config";

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE } = APP_ENV;

export const AUTH0_PARAMS = {
  clientId: AUTH0_CLIENT_ID || "",
  domain: AUTH0_DOMAIN || "",
  authorizationParams: {
    audience: AUTH0_AUDIENCE,
    scope: "openid profile email offline_access",
    redirect_uri: window.location.origin,
  },
  cacheLocation: "localstorage" as CacheLocation,
};
