import { Profile } from "../../interfaces";


/**
 * Checks if the profile data is complete.
 * @param {Profile} profile - The profile data object.
 * @returns {boolean} - Returns true if the profile data is complete, otherwise false.
 */
export const isProfileComplete = (profile: Profile): boolean => {
    return Boolean(profile.name
        && profile.email
        && profile.phone
        && profile.gender
        && profile.streetAddress
        && profile.country
        && profile.state
        && profile.city
        && profile.userEducations?.length);
  }