interface SiteName_URL_MAP {
  [siteName: string]: {
    fetchJobFilters: string;
    applyBaseURL: string;
  };
}
export const siteName_URL_MAP: SiteName_URL_MAP = {
  dice: {
    fetchJobFilters: "https://www.dice.com/jobs?filters.easyApply=true&q=",
    applyBaseURL: "https://www.dice.com/jobs?",
  },
  indeed: {
    fetchJobFilters: "https://www.indeed.com/jobs?q=",
    applyBaseURL: "https://indeed.com/jobs?",
  },
  ziprecruiter: {
    fetchJobFilters: "https://www.ziprecruiter.com/jobs-search?search=",
    applyBaseURL: "https://www.ziprecruiter.com/jobs-search?",
  },
};
