import "./auto-apply-form.scss";
import { Steps } from "antd";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import StepDot from "../../assets/icons/step-dot.svg";
import StepDotActive from "../../assets/icons/step-dot-active.svg";
import IndeedExpanded from "../../assets/icons/IndeedExpanded.png";
import DiceExpanded from "../../assets/icons/DiceExpanded.png";

import AutoApplyStepOne from "./steps/step-one";
import AutoApplyStepTwo from "./steps/step-two";
import autoApplyCardConfigs from "../auto-apply/auto-apply-card-config";

export default function AutoApplyForm() {
  const { formKey } = useParams();
  const [currentStep, setCurrentStep] = useState(0);

  function getStepItems() {
    return [0, 1].map((index) => {
      if (index === currentStep || index < currentStep) {
        return { icon: <img src={StepDotActive} alt='step icon active' /> };
      } else {
        return { icon: <img src={StepDot} alt='step icon' /> };
      }
    });
  }

  function renderFormsByActiveStep() {
    switch (currentStep) {
      case 0:
        return <AutoApplyStepOne siteName={formKey?.toLowerCase() || ""} />;
      case 1:
        return <AutoApplyStepTwo formSubmit={(formData) => console.log(formData)} />;
      default:
        return <></>;
    }
  }

  const config = useMemo(() => autoApplyCardConfigs.find((c) => c.formKey == formKey), [formKey]);

  return (
    <section className='auto-apply-form-wrapper'>
      <div className='form-logo'>
        <img width={150} src={config?.logoExpanded} alt={`${formKey} Logo`} />
      </div>
      {/* <Steps current={currentStep} items={getStepItems()} /> */}
      {renderFormsByActiveStep()}
    </section>
  );
}
