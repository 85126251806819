import { Popover, Image } from "antd";
import { useState } from "react";
import bellIcon from "./../../assets/icons/bell.svg";

export default function Notifications() {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const notificationList = () => (
    <div>
      <div>No Result Found.</div>
    </div>
  );

  return (
    <Popover
      content={notificationList()}
      title="Notifications"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      arrow={false}
      placement="bottomRight"
    >
      <Image
        className="cursor-pointer"
        src={bellIcon}
        width={30}
        height={40}
        preview={false}
      />
    </Popover>
  );
}
