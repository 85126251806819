import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./register-wizard-forms-customization.scss";
import ReviewCard from "../../review-card/review-card";
import { useDispatch, useSelector } from "../../../store";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import { LicenceAndCertificationFormData } from "../../../interfaces";
import { useCallback } from "react";

export interface Props {
  onEdit?: (key: number) => void;
  onAdd?: () => void;
  onNext?: () => void;
}

export default function LicenceAndCertificationReview(
  {
    onAdd = () => { },
    onNext = () => { },
    onEdit = () => { },
  }: Props
) {
  const dispatch = useDispatch();
  const { licenseCertifications = [] } = useSelector(selectOnboardingSlice);

  const onDelete = useCallback((key: number) => {
    dispatch(updateOnboarding({ licenseCertifications: licenseCertifications?.filter((_, index) => index !== key) }))
  }, [licenseCertifications, dispatch]);

  function renderReviewCardByValues() {
    if (!licenseCertifications?.length) {
      return null;
    }
    const items = licenseCertifications.map(
      (value: LicenceAndCertificationFormData, i: number) => ({
        key: i,
        title: value.certificationName,
        subtitles: [
          value.issuedBy,
          `${value.issueDateMonth}/${value.issueDateYear} - ${value.lifetimeAccess
            ? "Lifetime Access"
            : value.expirationDateMonth + "/" + value.expirationDateYear
          }`,
        ],
      })
    );
    return <ReviewCard items={items} onEdit={(key) => onEdit(key)}
      onDelete={(key) => onDelete(key)} />;
  }

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Review Certification and Licence</div>
      </div>
      <div className="review-card-list">{renderReviewCardByValues()}</div>
      <div className="form-buttons">
        <Button
          className="app-btn-primary"
          type="primary"
          ghost
          onClick={() => onAdd()}
        >
          <PlusOutlined />
          Add Another Certification or Licence
        </Button>
        <Button
          className="app-btn-primary"
          type="primary"
          onClick={() => onNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
