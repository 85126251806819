import { ScheduleOutlined } from '@ant-design/icons';
import { Rate } from 'antd';

const promoCardConfigs = [
  {
    header: <h4 style={{ margin: 0 }}>Daily Limit</h4>,
    icon: <ScheduleOutlined />,
    text: 'Unlimited',
    subtext: 'Apply to as many jobs as you need',
    cardBackgroundColor: '#4E36E2'
  },
  {
    header: <h4 style={{ margin: 0 }}>Your Plan Details</h4>,
    icon: <ScheduleOutlined />,
    text: 'Unlimited',
    subtext: '',
    cardBackgroundColor: '#1E86EE'
  },
  {
    header: <h4 style={{ margin: 0 }}>Total Jobs Applied</h4>,
    icon: <ScheduleOutlined />,
    text: '7642',
    subtext: '',
    cardBackgroundColor: '#1BCF85'
  },
  // {
  //   header: <Rate disabled defaultValue={5} />,
  //   icon: <ScheduleOutlined />,
  //   text: 'Appear in top 5',
  //   subtext: 'Upgrade to Pro Membership and get 1Ox search in top priorities',
  //   cardBackgroundColor: '#6E2595'
  // }
];

export default promoCardConfigs;