const typesOfEmployment = [
  "Part time",
  "Full time",
  "Contract - Corp to Corp",
  "Contract - Independent",
  "Contract W2",
  // "Contract to gire-Corp-to-corp",
  "Contract to hire independent",
  "Contract to hire-W2",
];

export default typesOfEmployment;
