import "./register-wizard-forms-customization.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import LicenceAndCertificationForm from "./licence-certification-form";
import LicenceAndCertificationReview from "./licence-certification-review";

export default function LicenceAndCertification() {
  const dispatch = useDispatch();
  const { licenseCertifications = [] } = useSelector(selectOnboardingSlice);
  const [selectedIndex, setSelectedIndex] = useState<number>(licenseCertifications.length === 0 ? 0 : -1);

  return selectedIndex > -1 ? (
    <LicenceAndCertificationForm
      formData={licenseCertifications[selectedIndex]}
      onSave={(values) => {
        dispatch(
          updateOnboarding({
            licenseCertifications:
              !licenseCertifications[selectedIndex] ? [...licenseCertifications, values]
                : licenseCertifications?.map((license, index) =>
                  selectedIndex === index ? values : license
                ),
          })
        );
        setSelectedIndex(-1);
      }}
    />
  ) : (
    <LicenceAndCertificationReview
      onEdit={(key) => setSelectedIndex(key)}
      onAdd={() => setSelectedIndex(licenseCertifications.length)}
      onNext={() => dispatch(updateOnboarding({ step: 5 }))}
    />
  );
}
