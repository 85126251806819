import { MouseEventHandler, ReactNode, useState } from "react";
import arrowRight from "../../assets/icons/arrow-right-circle.svg";
import ArrowRightIcon from "../../shared/svgs/arrowRightIcon";
import { Link } from "react-router-dom";

export type GenericFunction<T extends MouseEventHandler<HTMLAnchorElement>> = (event: T) => void;

export interface IPaymentCardProps {
  title: string;
  price: string;
  duration: string;
  points: { data: string[]; textColor: string };
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: ReactNode;
  textColor?: string;
  bgColor?: string;
  arrowFillColor?: string;
}

export default function SubscriptionCard({ cardData }: { cardData: IPaymentCardProps }) {
  const [hovering, setHovering] = useState(false);

  return (
    <div style={{ backgroundColor: cardData.bgColor || "#F3F7FA", color: cardData.textColor || "#110123" }} className={`card flex p-10 rounded-2xl shadow-slate-500 shadow-sm`}>
      <div
        onMouseOver={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        className='basis-1/4 text-center px-8 border-r border-gray-200'
      >
        <div>
          <p className='font-bold text-2xl mb-4 whitespace-nowrap'>{cardData.title}</p>
          <p className='font-bold text-7xl mb-2'>${cardData.price}</p>
          <p className='mb-8 text-sm font-thin  tracking-widest'>{cardData.duration}</p>
          <div className='flex justify-center items-center'>
            <span className={`${hovering ? "text-white bg-[#110123]" : " bg-white text-[#6D00E7]"} p-4 box-content rounded-full`}>{cardData.icon}</span>
          </div>
        </div>
      </div>
      <div className='basis-3/4 px-8 flex flex-col gap-2 justify-evenly items-start'>
        {cardData.points.data.map((point) => (
          <div className='flex justify-start items-center' key={point}>
            <span className='mr-4'>
              <ArrowRightIcon fillColor={cardData.arrowFillColor || "#6D00E7"} />
            </span>
            <p className={`text-lg text-${cardData.points.textColor}`}>{point}</p>
          </div>
        ))}

        {cardData.href && (
          <Link
            to={cardData.href}
            replace={true}
            className={"bg-[#110123] mx-auto mt-6 text-white  rounded-full  hover:text-[#6D00E7] hover:bg-white border-0 px-10 py-2 font-semibold uppercase text-lg"}
          >
            Start Now
          </Link>
        )}
        {cardData.onClick && (
          <button
            onClick={cardData.onClick}
            className={"bg-[#110123] mx-auto mt-6 text-white  rounded-full  hover:text-[#6D00E7] hover:bg-white border-0 px-10 py-2 font-semibold uppercase text-lg"}
          >
            Start Now
          </button>
        )}
      </div>
    </div>
  );
}
