import "./onboarding.scss";
import { Button, Progress, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PersonalDetailsForm from "../../components/forms/onboarding/personal-details";
import LocationForm from "../../components/forms/onboarding/location";
import Education from "../../components/forms/onboarding/education";
import WorkExperienceForm from "../../components/forms/onboarding/work-experience";
import RelevantLinksForm from "../../components/forms/onboarding/relevant-links";
import LicenceAndCertificationForm from "../../components/forms/onboarding/licence-certification";
import ReviewOnboarding from "./review-onboarding/review-onboarding";
import { selectOnboardingSlice, updateOnboarding } from "../../store/slices";
import { useDispatch, useSelector } from "../../store";
import PATH_DEFINITIONS from "../../shared/constants/path-definitions";
import { useOnboardingMutation } from "../../store/apis";

export default function OnboardingPage() {
  const { step: activeOnboardingStep } = useSelector(selectOnboardingSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitOnboarding, { isLoading }] =
    useOnboardingMutation();

  function renderFormByActiveOnboardingStep(): JSX.Element {
    switch (activeOnboardingStep) {
      case 0:
        return <PersonalDetailsForm />;
      case 1:
        return <LocationForm />;
      case 2:
        return <Education />;
      case 3:
        return <WorkExperienceForm />;
      case 4:
        return <LicenceAndCertificationForm />;
      case 5:
        return <RelevantLinksForm inline={false} />;
      default:
        return <ReviewOnboarding />;
    }
  }

  function handleBackButtonClick(): void {
    if (activeOnboardingStep > 0) {
      dispatch(updateOnboarding({ step: activeOnboardingStep - 1 }));
    } else {
      navigate("/login");
    }
  }

  const onSkipOnboarding = async () => {
    await submitOnboarding({});
    navigate(PATH_DEFINITIONS.dashboard);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="register-wizard-form-wrapper">
        <section className="register-wizard-form-section">
          <div className="register-wizard-form-header">
            <div>
              {activeOnboardingStep !== 0 && (
                <Button
                  className="back-btn"
                  type="link"
                  onClick={() => handleBackButtonClick()}
                >
                  <ArrowLeftOutlined />
                </Button>
              )}
            </div>
            <Button
              className="skip-btn"
              type="link"
              onClick={() => {
                if (activeOnboardingStep <= 5) {
                  dispatch(
                    updateOnboarding({ step: activeOnboardingStep + 1 })
                  );
                } else {
                  onSkipOnboarding();
                }
              }}
            >
              Skip for now
            </Button>
          </div>
          <div className="register-wizard-form-inner">
            <Progress
              className="form-progress-bar"
              percent={(activeOnboardingStep + 1) * 15}
              showInfo={false}
            />
            {renderFormByActiveOnboardingStep()}
          </div>
        </section>
      </div>
    </Spin>
  );
}
