import { Profile } from "../../interfaces";
import { baseApi } from "./base.api";

export const profilesApi = baseApi("profilesApi").injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getProfiles: build.query<GetProfileRes, void>({
      query: (body) => ({
        url: `/profiles`,
        method: "GET",
        body,
      }),
    }),
    createProfile: build.mutation<CreateProfileRes, CreateProfileReq>({
      query: (body) => ({
        url: `/profiles`,
        method: "POST",
        body,
      }),
    }),
    updateProfile: build.mutation<CreateProfileRes, UpdateProfileReq>({
      query: (body) => ({
        url: `/profiles/${body.id}`,
        method: "PATCH",
        body,
      }),
    }),
    updateProfileArray: build.mutation<UpdateProfileRes, UpdateProfileArrayReq>({
      query: (body) => ({
        url: `/profiles/${body.profileId}`,
        method: "PATCH",
        body: body.updateData,
      }),
    }),
    deleteProfile: build.mutation<void, DeleteProfileReq>({
      query: (body) => ({
        url: `/profiles/${body.id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const { useGetProfilesQuery, useCreateProfileMutation, useUpdateProfileMutation, useDeleteProfileMutation, useUpdateProfileArrayMutation } = profilesApi;

export type UpdateProfileRes = {
  data: Profile;
};

export type GetProfileRes = {
  data: Profile[];
};

export type CreateProfileReq = {
  id?: string;
  email?: string;
  name?: string;
  phone?: string;
  gender?: string;
  streetAddress?: string;
  city?: string;
  profileName?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  coverLetter?: string;
  relevantLinks?: { link: string; name: string }[];
  licenseCertifications?: any[];
  workExperiences?: any[];
  userEducations?: any[];
  questions?: any[];
};

export type UpdateProfileReq = {
  id: string;
  email?: string;
  name?: string;
  phone?: string;
  gender?: string;
  streetAddress?: string;
  profileName?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  coverLetter?: string;
  relevantLinks?: { link: string; name: string }[];
  licenseCertifications?: any[];
  workExperiences?: any[];
  userEducations?: any[];
  questions?: any[];
};

export type CreateProfileRes = {
  data: ProfileRes;
};

export interface ProfileRes extends Profile {
  profileId?: string;
}

export type DeleteProfileReq = {
  id: string;
};

export type UpdateProfileArrayReq = {
  profileId: string;
  updateData: {
    [field: string]: {
      id: string;
      delete?: true;
      [keys: string]: any;
    };
  };
};
