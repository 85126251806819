import { User } from "../../interfaces";
import { baseApi } from "./base.api";

export const authApi = baseApi("authApi").injectEndpoints({
  overrideExisting: false,

  endpoints: (build) => ({
    getUser: build.query<GetUserRes, void>({
      query: (body) => ({
        url: `/user`,
        method: "GET",
        body,
      }),
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = authApi;

export type LoginRes = {
  token: string;
  user: User;
};
export type LoginReq = {
  email: string;
  password: string;
};

export type GetUserRes = User
