import "./payment-form.scss";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { APP_ENV } from "../../shared/constants";
import { useSelector } from "react-redux";
import { selectAuthSlice } from "../../store/slices";
import { Button } from "antd";

export default function PaymentForm({ price, name }: { price: number; name: string }) {
  const stripe = useStripe() as Stripe;
  const elements = useElements() as StripeElements;
  const [loading, setLoading] = useState(false);
  const state = useSelector(selectAuthSlice);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error(submitError);
      return;
    }

    const res = await fetch(`${APP_ENV.BASE_URL}/payment/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: `Bearer ${state.token}` },
      body: JSON.stringify({ items: [{ id: "price_1PGieDJVWM8UzOW3DhZoOaD1", amount: 5900 }] }),
    });

    const { clientSecret } = await res.json();

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONTEND_PATH}/payment/success` as string,
      },
      // Uncomment below if you only want redirect for redirect-based payments
      // redirect: "if_required",
    });
    setLoading(false);
  };

  return (
    <div className='flex justify-between mx-auto w-[64rem] p-8 bg-[#F3F7FA] rounded-lg shadow shadow-slate-500'>
      <div className='px-8 border-r-slate-300 border-r flex-grow'>
        <div className='mb-8'>
          <p className='text-lg font-bold text-slate-400'>Pay NextRole.ai</p>
          <p className='text-3xl text-slate-800 font-bold'>${price / 100}</p>
        </div>
        <div className='flex justify-between text-slate-500 text-lg'>
          <p>{name}</p>
          <p className='font-bold'>${price / 100}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className='px-8 flex-grow'>
        <h4 className='text-xl font-bold mb-6 text-slate-500'>Payment Details</h4>
        <PaymentElement />
        <Button type='primary' block size='large' className='mt-4' htmlType='submit' onSubmit={handleSubmit} disabled={!stripe || loading}>
          Pay ${price / 100}
        </Button>
      </form>
    </div>
  );
}
