import "./register-wizard-forms-customization.scss";
import { useState } from "react";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import { useDispatch, useSelector } from "../../../store";
import WorkExperienceForm from "./work-experience-form";
import WorkExperienceReview from "./work-experience-review";


export default function WorkExperience() {
  const dispatch = useDispatch();
  const { workExperiences = [] } = useSelector(selectOnboardingSlice);
  const [selectedIndex, setSelectedIndex] = useState<number>(workExperiences.length === 0 ? 0 : -1);

  return selectedIndex > -1 ? (
    <WorkExperienceForm
      formData={workExperiences[selectedIndex]}
      onSave={(values) => {
        dispatch(
          updateOnboarding({
            workExperiences:
              !workExperiences[selectedIndex] ? [...workExperiences, values]
                : workExperiences?.map((workExp, index) =>
                  selectedIndex === index ? values : workExp
                ),
          })
        );
        setSelectedIndex(-1);
      }}
    />
  ) : (
    <WorkExperienceReview
      onEdit={(key) => setSelectedIndex(key)}
      onAdd={() => setSelectedIndex(workExperiences.length)}
      onNext={() => dispatch(updateOnboarding({ step: 4 }))}
    />
  );
}
