export enum QUESTION_TYPES {
  text = "text",
  number = "number",
  single_choice = "single_choice",
  multi_choice = "multi_choice",
}

export const questionOptions = [
  {
    label: "Text",
    value: QUESTION_TYPES.text,
  },
  {
    label: "Number",
    value: QUESTION_TYPES.number,
  },
  {
    label: "Single Choice",
    value: QUESTION_TYPES.single_choice,
  },
  {
    label: "Multiple Choice",
    value: QUESTION_TYPES.multi_choice,
  },
];
