import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./register-wizard-forms-customization.scss";
import ReviewCard from "../../review-card/review-card";
import { EducationFormData } from "../../../interfaces";
import { useDispatch, useSelector } from "../../../store";
import { selectOnboardingSlice, updateOnboarding } from "../../../store/slices";
import { useCallback } from "react";

export interface Props {
  onEdit?: (key: number) => void;
  onAdd?: () => void;
  onNext?: () => void;
}

export default function EducationReview({
  onAdd = () => {},
  onNext = () => {},
  onEdit = () => {},
}: Props) {
  const dispatch = useDispatch();
  const { userEducations = [] } = useSelector(selectOnboardingSlice);

  const onDelete = useCallback((key: number) => {
    dispatch(updateOnboarding({ userEducations: userEducations?.filter((_, index) => index !== key) }))
  }, [userEducations, dispatch]);

  const renderReviewCardByValues = useCallback(() => {
    if (!userEducations?.length) {
      return null;
    }
    const items = userEducations.map((value: EducationFormData, i: number) => ({
      key: i,
      title: value.fieldOfStudy,
      subtitles: [
        value.school || "",
        value.levelOfEducation || "",
        `${value.monthFrom || ""}/${value.yearFrom || ""} - ${value.currentlyStudying
          ? "Present"
          : `${value.monthTo || ""}${value.monthTo ? "/" : ""}` +
          `${value.yearTo || ""}`
        }`,
      ],
    }));
    return (
      <ReviewCard
        items={items}
        onEdit={(key) => onEdit(key)}
        onDelete={(key) => onDelete(key)}
      />
    );
  }, [userEducations, onEdit, onDelete]);

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="form-title">Review Education</div>
      </div>
      <div className="review-card-list">{renderReviewCardByValues()}</div>
      <div className="form-buttons">
        <Button
          className="app-btn-primary"
          type="primary"
          ghost
          onClick={() => onAdd()}
        >
          <PlusOutlined />
          Add Another Education
        </Button>
        <Button
          className="app-btn-primary"
          type="primary"
          onClick={() => onNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
